.savedItems{
    flex: 4;
}

.userListUser{
    display: flex;
    align-items: center;
}
.userListImg{
    width: 90px;
    height: 50px;
    border-radius: 5%;
    object-fit: cover;
    margin-right: 10px;
}


