.search-bar-dropdown{
    width: 30em;
    display: block;
}

#result{
    position: absolute;
    width: inherit;
    scroll-behavior:smooth;
}
.location-search-form{
    top:-15px;
    border-radius: 5px;
}

#search-bar{
    width: inherit ;
}

.listOptions{
    display: flex;
    flex: 1;
    
}
.groupItems :first-child{
    justify-content: right;

}
.groupItems :nth-child(n+2) {
   justify-content: left;  
   }
   .groupItems :nth-child(3){
    flex: 2;
   }
.groupItems{
    display: flex;
    flex:1;

}