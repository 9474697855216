.user{
    flex: 4;
   color: rgb(114, 105, 100)
}
.userTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userAddButton{
    border: none;
    width: 80px;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 1rem;
}

.userContainer{
    display: flex;
}

.userTitle{
    margin-left: 20px;
    font-weight: 600;
}

.userShow{
    flex: 1;
    -webkit-box-shadow: -5px 5px 2px 3px rgba(0, 0, 0, 0.233);
    box-shadow: -3px 3px 15px 3px rgba(0, 0, 0, 0.223);
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    background-color: white;
    }

.userUpdate{
    flex: 2;
/* -webkit-box-shadow: -5px 5px 32px 10px rgba(0, 0, 0, 0.3); */
/* box-shadow: -5px 5px 32px 5px rgba(0, 0, 0, 0.3); */
/* padding: 20px;
margin: 20px; */
border-radius: 10px;
display: none;
justify-content: space-between;
    }

#EditprofileBtn:checked ~ .userUpdate{
        display: block;
    }

/* .userShowTop{
    display: flex;
    align-items: center;
} */

.userShowImg-profile{
width: 150px;
height: 150px;
border-radius: 50%;
object-fit: cover;
margin-right: 10px;
}
.userShowImg{
    width: 100px;
    height: 100px;
    /* border-radius: 50%; */
    object-fit: cover;
    margin: 10px;
    margin-top: 30px;
    }
 
.userShowTopTitle{
    display: flex;
    flex-direction: column;
    font-weight: 600;
   
}
.userShowBottom{
    margin-top: 2rem;
}

.userShowUserTitle{
    font-weight: 300;
}
.userShowTitle{
    font-size: 18px;
    font-weight: 800;
    margin-top: 2rem;
    color: rgb(86, 66, 55);
   
}
.userShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: rgb(130, 121, 115);
    font-size: 16px;
}
.userShowIcon{
    margin-right: 5px;
    font-size: 1rem;
}
.userShowInfoTitle{
margin-left: 5px;
}
.userUpdateTitle{
    font-size: 1.5rem;
    font-weight: 600;
}
.userUpdateRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.userUpdateUpload{
    display: flex;
    align-items: center;


}
.UserUpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 20px;
}
.userUpdateIcon{
    cursor: pointer;
}

.userUpdateForm{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.userUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;

}

.userUpdateItem>label{
    margin-bottom: 5px;
    font-size: 1rem;
    margin-top: 10px;

}
.userUpdateInput{
    border: none;
    font-size: 14px;
    /* height: 30px;
    width: 250px; */
    border-bottom: 1px solid gray;
}

.userUpdateButton{
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    
    font-weight: bold;

}
.userUpdateItem #textarea{
height: auto;
}
.passwordChange{
    margin-top: 3rem;
    font-size: 16px;
   
}
.passwordChange > label:hover{
color: rgb(152, 137, 101);

}
.passwordChange > input , .passwordChangeForm{
    display: none;
    margin-left: 1rem;
 
}
.passwordChange .arrowForwardIcon{   
    width: 12px;
    height:12px ;    
    
    }
.passwordChange .arrowForwardIcon{  
    transition: 0.3s ease-in-out;
    /* transform: rotate(0deg); */
}

.passwordChange > input:checked ~ .passwordChangeForm{    
    display: block;
   
    
}

.passwordChange > input:checked ~ label >.arrowForwardIcon{
    transform: rotate(-90deg);
   color: rgb(232, 241, 96)
}
/* 
.userUpdateRight .userUpdateUpload{
    display: flex;
    flex-direction: column;
    justify-content: left;
} */



/* .control-me {
    content: "😃";
    font-size: 100px;
    color: aquamarine;
} */

#sidebar-nav {
    width: 160px;
}
.userUpdateInputTextarea{

 /* padding-top: 0rem; */
 border-radius: 5px;
 box-shadow: none;
 border: 2px solid #857b7490;
 font-weight: normal;
  background: transparent;
  transition: 0.3s ease-out;
      
       
}