.userList{
   flex: 4;
   color: rgb(61, 47, 10);
}

.userListUser{
    display: flex;
    align-items: center;
}
.userListImg{
    width: 70px;
    height: 40px;
    border-radius: 3px;
    object-fit: cover;
    /* margin-right: 10px; */
}

.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 10px 10px;
    background-color: #898659!important;
    color: white;
    cursor: pointer;margin: 0;
    /* margin-right: 20px; */
    width: 100%;
}
.userListTitle{
    border: none;
    border-radius: 3px;
    padding: 10px 10px;
    background-color: #dbd8b1!important;
    color: var(#423a2c);
    cursor: pointer;margin: 0;
    /* margin-right: 20px; */
    width: 100%;
}



.userListDelete{
    color: rgb(211, 124, 77);
    cursor: pointer;
    background-color: transparent;
}