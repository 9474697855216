.ReviewContainer{
    display: flex;
    justify-content:space-between;
    width: 100%;
}

.leftContainer{
    flex: 1;
    -webkit-box-shadow: -9px 12px 32px 10px rgba(0, 0, 0, 0.3);
    box-shadow: -9px 12px 32px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    
}

.middleContainer{
    flex: 1;
    -webkit-box-shadow: -9px 12px 32px 10px rgba(0, 0, 0, 0.3);
    box-shadow: -9px 12px 32px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    
}

.RightContainerAcc{
    display: flex;
    flex: 1;
    -webkit-box-shadow: -9px 12px 32px 10px rgba(0, 0, 0, 0.3);
    box-shadow: -9px 12px 32px 10px rgba(0, 0, 0, 0.3);
    /* padding: 20px; */
    margin: 20px;
    border-radius: 10px;
    justify-content: start;
    flex-direction: column;
}
.RightContainerSec{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    flex: 1;
    font-size: 1rem;
    font-weight: 500;
   
}
.blogAccess{
display: flex;
flex: 1;
flex-direction: column;
align-items:flex-start;
width: 100%;
margin: .5rem;
padding: 0%;

}

.accessRows{
    -webkit-box-shadow: -9px 12px 32px 10px rgba(0, 0, 0, 0.3);
    box-shadow: -9px 12px 32px 10px rgba(0, 0, 0, 0.3);
    margin: 5px 5px 5px 0px;
    border-radius: 10px;
    padding: 2px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;    
}




