.nav-bg{
  /* background-color: #715e37eb !important; */
  background-color: #473c31c8 !important;
  border-radius: 30px;
  color: white;
}
.nav-active{
  /* color: #f08805 !important; */
  background-color: var(--beige) !important;
  font-weight: bold;
  color: #473c31  !important;
}
.nav-app-btn{
  border: 1px solid white;;
  border-radius: 7px;
  width: 32px;
  height: 32px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  color: white;
  font-weight: lighter;
}
.small-beige-font {
  font-size: 12px;
  color: white;
}
.sticky-bot{
  position: fixed;
  bottom: 0;
}

/* .navbar_component {
  display: flex;
  width: 100vw;
  height: 60px;
  background-color: #131a22;
}

.navbar_logo {
  background: url("../../../public/img/sprite_350x.png") no-repeat -9px -50px;
  width: 98px;
  height: 31px;
  margin: 18px 18px 18px 0px;
}

.navbar_locator {
  display: flex;
  margin: 20px 0px;
}

.navbar_location {
  color: white;
  font-weight: bold;
  margin-left: 5px;
}

.navbar_locatorImage {
  background: url("../../../public/img/sprite_350x.png") no-repeat -70px -305px;
  width: 19px;
  height: 22px;
}

.navbar_search {
  display: flex;
}

.navbar_searchbox {
  width: 540px;
  height: 0.65rem;
  margin-top: 8px;
}
.navbar_searchboxdiv {
  background-color: #febe68;
  height: 44px;
  width: 48px;
  border-radius: 0px 5px 5px 0px;
  margin-top: 8px;
}

.navbar_searchicon {
  background: url("../../../public/img/sprite_350x.png") no-repeat -9px -289px;
  width: 23px;
  height: 23px;
  margin: 13px;
}
.nav_dropdown {
  width: 60px;
  height: 44px;
  margin-top: 8px;
  background-color: #e6e5e5;
  border-radius: 5px 0px 0px 5px;
  text-align: center;
}

.navbar_signin {
  width: 120px;
  margin-top: 10px;
  margin-left: 50px;
}

.navbar_returns {
  width: 80px;
  margin-top: 10px;
  margin-left: 10px;
}

.navbar_cart {
  display: flex;
  margin-top: 10px;
}
.navbar_text {
  color: white;
}

.cart_image {
  background: url("../../../public/img/sprite_350x.png") no-repeat -217px -336px;
  width: 43px;
  height: 30px;
}

.navbar_text_cart {
  margin-top: 12px;
  font-weight: bold;
}
.cart_item {
  font-weight: bold;
  position: relative;
  left: -23px;
  top: -5px;
  color: #f08805;
}

.navigation_footer {
  display: flex;
  width: 100vw;
  height: 30px;
  background-color: #22303e;
  color: white;
}

.navbar_footer_text {
  color: white;
  margin: 5px 10px;
  font-size: 14px;
  font-weight: bold;
} */

/* .topAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
  margin: 5px;
} */
/* .topAvatar{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
} */

