* {
  box-sizing: border-box;
}

/* Position the image container (needed to position the left and right arrows) */
.container-bus-slider {
  position: relative;
  /* padding-bottom: 46.25%;  */
}
.container-bus-big-img-slide {
  position: relative;
  padding-bottom: 56.25%;
}
.container-ad-sq-img-slide {
  position: relative;
  padding-bottom:100%;
}
.container-ad-2to1-img-slide {
  position: relative;
  padding-bottom:50%;
}

.container-bus-big-img-slide img, .container-ad-sq-img-slide img, .container-ad-2to1-img-slide img{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  border-radius: 5px;
  object-fit: cover;
  padding: 0;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Add a pointer when hovering over the thumbnail images */
.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 40%;
  
  width: 50px;
  height: 50px;
  padding: 10px;
  /* margin-top: -50px; */
  color: rgba(224, 196, 135, 1);
  font-weight: bold;
  font-size: 30px;
  border-radius: 50%;
  /* border-radius: 0 3px 3px 0; */
  user-select: none;
  -webkit-user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  transition: transform 0.5s ease-in-out;
}



/* Position the "next button" to the right */
.next {
  left: 0;
  /* border-radius: 3px 0 0 3px; */
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(140, 130, 103, 0.5);
  color: beige;

  transition: transform 0.5s ease-in-out;
}


/* Number text (1/3 etc) */
.numbertext {
  color: rgb(224, 196, 135, 1);
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 30;
  z-index: 120;
}

/* Container for image text */
.caption-container {
  text-align: center;
  vertical-align: middle;
  background: rgb(215,205,187);
background: linear-gradient(90deg, rgba(215,205,187,1) 0%, rgba(181,166,142,1) 35%, rgba(157,142,117,1) 65%);
  padding: 2px 16px;
  color: rgb(250, 250, 242);
  margin-top: 10px;
  font-size: larger;
  border-radius: 5px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Six columns side by side */
.column {
  float: left;
  width: 16.66%;
}

/* Add a transparency effect for thumnbail images */
@media screen and (min-width: 771px) {.demo {
  opacity: 0.5;
  object-fit: cover;
  border-radius: 5px;
  height: 100px;
  filter: grayscale(50%);
  }
}

@media only screen and (max-width: 770px) {
  .container-modal-image .demo {
    height: 30px;
    opacity: 0.6;
    object-fit: cover;
    border-radius: 5px;
    filter: grayscale(100%);
  }
}
.active,
.demo:hover {
  opacity: 1;
  filter: none;
  /* border: 2px solid red; */
}


.container-bus-slider {
  width: 100%;
}
.slick-prev, .slick-next{
  width: 30px;
  height: 30px;
  margin-left: 7px;
  margin-right: 7px;
  z-index: 5;
}
.slick-prev::before, .slick-next::before{
 font-size: 30px; 
 color: rgba(128, 113, 88, 0.423);
}
.dark-background .slick-prev::before, .dark-background .slick-next::before{
  color: rgba(228, 211, 181, 0.825);
 }
.slick-prev:hover::before, .slick-next:hover::before{
  
  color: rgba(137, 115, 79, 0.887);
  transition: all .3s ease-in-out;

 }