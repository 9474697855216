.topbar{
    width: 100%;
    height: 50px;
    background-color: white;
    /* display: flex; */
    /* justify-content: center; */
    position: sticky;
    top: 0;
    z-index: 999;
}

.topbarWrapper{
height: 100%;
padding: 0px 20px;
display: flex;
align-items: center;
justify-content: space-between;
}

.logo{
    font-weight: bold;
    font-size: 30px;
    color: darkblue ;
}

.topbarIconContainer{
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;

}
.topRight{
    display: flex;
    align-items: center;
}

.topIconBag{
    width: 15px;
    height: 15px;
    position: absolute;
    top : -5px;
    right: 0px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.topAvatar{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
  
}
.topAvatar2{
    width: 35px;
    height: 35px;
    /* border-radius: 50%; */
    cursor: pointer;
    object-fit: cover;
    /* filter: sepia(.5); */
  
}