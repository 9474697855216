html {
  font-size: 100%;
  /* allways use this */
  font-family: vazir, Helvetica, "sans-serif";
  padding: 0;
  margin: 0;


/* overflow-x: hidden; */
width: 100vw;
max-width: 100%;
/* height: 100vh; */
}

@font-face {
  font-family: Vazir;
  src: url(../public/Fonts/Vazir-Regular.eot);
  src: url(../public/Fonts/Vazir-Regular.eot) format("embedded-opentype");
  src: url(../public/Fonts/Vazir-Regular.woff2) format("woff2");
  /* src: url(../public/Fonts/Vazir-regular.woff) format("woff"); */
  src: url(../public/Fonts/Vazir-Regular.ttf) format("truetype");

  font-size: 100px;
  /* font-weight: bold;  */
  font-style: normal;
}
@font-face {
  font-family: "Vazir-bold";
  src: url(../public/Fonts/Vazir-Regular.eot);
  /*src: url(../public/Fonts/Vazir-bold.eot)
    format("embedded-opentype"); */
  /* src: url(../public/Fonts/Vazir-bold.woff2) format("woff2"); */
  /* src: url(http://metamarce.com/Fonts/Vazir-bold.woff) format("woff"); */
  /* src: url(../public/Fonts/Vazir-bold.ttf) format("truetype"); */

  font-size: 100px;
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Lalezar-Regular";
  src: url(../public/Fonts/Lalezar-Regular.ttf);
  font-size: 100px;
  font-weight: bold;
  font-style: normal;
}

body {
  /* font-size: 90%; */
  /* allways use this */
  font-family: "vazir";
  /* max-width: 100%; */
  /* overflow: hidden; */
  /* max-width: 2100px; */
  margin: 0 auto !important;
  float: none !important;
  /* background-color: #fdf1f0 !important; */
  background-color: #f7f5f3 !important;
  overflow-x: hidden;
  max-width: 100vw !important;
  
}

:root {
  --brown: #7b635ae4;
  --white: #faf0e6;
  --golbehi: #c2906f97;
  --pink: #f8e3d5;
  --darkBrown: rgb(84, 58, 50);
  --brown2: #74563c;
  --brownPink: #e5d9cd;
  --brownOrange: #b18a61;
  --beige: #eeeae1;
  --brownGray: #7e685b;
  --warmGrayBeige: rgb(211, 192, 178);
  --khaki: #c3b091;
}

a,
link {
  
  text-decoration: none;
  color: var(--brownGray);
}

.required::before {
  content: "   *  ";
  color: rgb(142, 52, 52);
  font-size: 15px;
}
.required:di
/* ********Shop****************** */
.offcanvas-cart {
  margin-bottom: 2rem;
  margin-top: 3.5rem;
  transition: transform 0.5s ease-in-out;
  border: 2px;
}
.btn:focus {
  box-shadow: none;
}
.btn:active,
.btn:focus-visible,
.btn:focus {
  background-color: var(--brownOrange) !important;
  border: none !important;
  box-shadow: none !important;
}
.offcanvas-footer {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}
.offcanvas-metamarce {
  background-color: #fff;
}
.ti-close {
  font-size: 30px;
}

.cart-hover {
  position: absolute;
  right: -70px;
  top: 100px;
  width: 350px;
  background: #ffffff;
  z-index: 99;
  text-align: left;
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 0 13px 32px rgba(51, 51, 51, 0.1);
  box-shadow: 0 13px 32px rgba(51, 51, 51, 0.1);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.select-items table {
  width: 100%;
  /* overflow-y: auto; */
}
.select-items {
  overflow-y: auto;
}

.select-items table tr td {
  padding-bottom: 20px;
}
.icon-m-t {
  position: absolute;
  top: 10px;
}
.si-pic img {
  border: 1px solid var(--beige);

  width: 90px;
  height: 90px;
  background-color: var(--beige);
  /* padding: 5px; */
}

.select-items table tr td .si-text {
  padding-left: 18px;
}

.select-items table tr td .product-selected p {
  color: #e7ab3c;
  line-height: 30px;
  margin-bottom: 7px;
  font-size: smaller;
}

.select-items table tr td .product-selected h6 {
  color: #4f2c12;
}

.select-items table tr td .si-close {
  /* color: #252525;
  font-size: 16px; */
  cursor: pointer;
}
.map-dir:hover{
  color: var(--brownOrange);
}
.select-total {
  overflow: hidden;
  border-top: 1px solid #e5e5e5;
  padding-top: 26px;
  margin-bottom: 30px;
}

.select-total span {
  font-size: 14px;
  color: #e7ab3c;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  float: left;
}

.select-total h5 {
  color: var(--brownOrange);
  float: right;
  font-weight: bold;
}

.view-cart {
  font-size: 14px;
  letter-spacing: 2px;

  text-align: center;
  background: #4d412e;
  /* background: #74563c; */
  color: #ffffff;
  padding: 12px 30px 12px;
  margin-bottom: 10px;
}
.view-cart-2{
  color: #4d412e;
  background:transparent;
  border-color: #4d412e;
  
}

.province-btn {
  height: 20px;
  font-size: 10px;
  letter-spacing: 2px;
  border-radius: 3px;
  text-align: center;
  background: #ead6ab;
  /* background: #74563c; */
  color: var(--darkBrown);
  font-style: italic;

  bottom: 30px;
}
.filter-del-btn{
  background-color: var(--brownPink);
  font-size: 10px;
  width: max-content;
  height: 1.5rem;
  color: var(--brownGray) !important;
  border-radius: 20px;
}
.filter-del-btn:hover{
  background-color: var(--beige);
  cursor: pointer;
  transition:all 0.3s ease-in-out;
}

.cart-footer {
  display: block;
}
.nav-mob-btn {
  font-size: 14px;
  letter-spacing: 2px;

  text-align: center;
  color: #ffffff;
  padding: 12px 30px 12px;
  background: #846c51;
}
.my-posts-btn {
  font-size: 12px;
  width: 200px;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  padding: 2px ;
  background: #958c65;
  border-radius: 5px;
  cursor: pointer;
}
.checkout-btn ,.checkout-btn  a{
  font-size: 14px !important;
  letter-spacing: 2px;

  text-align: center;
  color: #ffffff !important;
  padding: 12px 30px 12px;
  background: #b18a61;
}
.checkout-btn-narrow {
  padding: 7px 20px 5px;
  background: #c0a07e;
}
.my-dash-btn {
  font-size: 14px;
  letter-spacing: 2px;
  text-align: center;
  color: #ffffff;
  padding: 5px 30px 5px;
  background: #81743d;
}
.my-dash-btn:hover , .my-dash-btn:focus ,.my-dash-btn:active ,.my-dash-btn:focus-visible {
  color: #7b6a35 !important;
  background-color: #ccc29b !important;
  border-color: #7b6a35 !important;
  transition: all 0.3 ease-in-out;
}
.checkout_body { 
  background-color: #eaeded;
  height: 100vh;
}
.checkout_container {
  background-color: white;
  /* margin:25px ;
    width:70vw ; */
}

.textgap {
  margin: 5px;
}
.placeorder_button {
  width: 220px;
  height: 40px;
  border-radius: 10px;
  margin: 5px;
  border: 0px;
  color: black;
  background-color: #ffd712;
}

.qty-selector {
  width: 550px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Checkout Steps*/
.chechoutSteps {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin: 0px 1rem;
}

.chechoutSteps > div.active {
  margin-top: -0.3rem;
  font: bold red;
  border-top: #f08000 solid 0.3rem;
  color: #f08000;
}

.empty-cart img {
  max-width: 100%;
  height: auto;
}
/* .checkout-col {
  min-width: 300px;
} */
@media only screen and (max-width: 576px) {
  .display-none-sm {
    display: none !important;
  }
}
@media only screen and (max-width: 768px) {
  .display-none-md {
    display: none !important;
  }
}
@media only screen and (max-width: 992px) {
  .display-none-lg {
    display: none !important;
  }
}
@media only screen and (min-width: 577px) {
  .display-moblie {
    display: none !important;
  }
}
@media only screen and (min-width: 769px) {
  .display-tablet {
    display: none !important;
  }
}
.promo-code {
  height: 30px;
}
.promo-code .btn {
  height: 30px;
  font-size: 12px;
  margin: 0;
  text-align: center;
  font-weight: normal;
}
.no-wrap{
  flex-wrap: nowrap !important;
}
.wrap{
  white-space:nowrap !important;
  /* width: fit-content !important; */
}
.checkout-cart-img {
  width: 100px;
  height: 135px;
  object-fit: contain;
}

.urgent-btn {
  position: absolute !important;
  /* transform: rotate(-45deg); */
  top: 30px;
  left: 30px;
}
.edit-btn {
  font-size: 12px;
  letter-spacing: 2px;

  text-align: center;
  color: #ffffff;
  padding: 10px 20px 10px;
  background: #b3a96c;
  /* background: #e7ab3c; */
}
.delete-btn {
  font-size: 12px;
  letter-spacing: 2px;

  text-align: center;
  color: #ffffff;
  padding: 10px 20px 10px;
  background: #bc823e;
  /* background: #e7ab3c; */
}
.close-btn {
  font-size: 12px;
  letter-spacing: 2px;

  text-align: center;
  color: #ffffff;
  padding: 10px 20px 10px;
  background: #b0a69d;
}
.button{
  color: (123, 99, 90, 0.894);
}
.icon-btn {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  color: (123, 99, 90, 0.894) !important;
}

.reset-btn {
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  padding: 3px 15px 3px;
  background: #b18a61;
  margin-bottom: 5px;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.catAndSubcat .catAndSubcatForm {
  display: none;
  margin-left: 1rem;
}

.catAndSubcat input:checked ~ .catAndSubcatForm {
  display: block !important;
}

.reset-btn:hover,
.checkout-btn:hover,
.view-cart:hover,
.close-btn:hover,
.edit-btn:hover,
.terms-con:hover {
  background-color: var(--khaki) !important;
  color: var(--brown2) !important;
}
.view-cart2:hover {
  color: #fbe08e !important;
}
.exit-footer {
  background-color: var(--brown);
  padding: 0;
  margin: 0;
  height: 60px;
  width: 100%;
  cursor: pointer;
  color: beige;
  font-size: 18px;
  
}
.exit-footer:hover{
  color: var(--brownPink);
  text-shadow: 1px 1px 3px rgba(252, 231, 154, 0.851);
  transform: scale(1.01);
}
.bg-nav-soft-dark {
  background-color: #473c31;
}
.overflow-x-scroll {
  overflow-x: auto;
}
/* *** to make underline for links *** */
.nav-title {
  text-decoration: none;
  -webkit-text-decoration: none;
  text-underline-offset: 0.3em;
  -webkit-text-underline-offset: 0.3em;
  color: var(--brownGray) !important;
  -webkit-text-decoration-thickness: 3px;
  text-decoration-thickness: 3px;
  cursor: pointer;
}
.nav-title:hover {
  text-decoration: underline var(--brownOrange);
  -webkit-text-decoration: underline var(--brownOrange);
  text-underline-offset: 0.3em;
  -webkit-text-underline-offset: 0.3em;
  color: var(--brownOrange) !important ;
  -webkit-text-decoration-thickness: 3px;
  text-decoration-thickness: 3px;
  transition: all 0.3s ease-in-out;
}
.navbar-nav .active {
  text-decoration: underline var(--brownOrange);
  -webkit-text-decoration: underline var(--brownOrange);
  text-underline-offset: 0.3em;
  -webkit-text-underline-offset: 0.3em;
  color: var(--darkBrown) !important ;
  font-weight: bold;
  -webkit-text-decoration-thickness: 3px;
  text-decoration-thickness: 3px;
}

.bg-nav {
  background-color: rgb(247, 244, 237);
}

.footer-social img {
  display: inline;
  max-width: 20px;
  max-height: 20px;

  transform: rotateY(360deg);
  transition: all 0.3s;
}
.footer-social a:hover img {
  transform: rotateY(360deg);
}
.footer-social link:hover img {
  transform: rotateY(360deg);
}
/* (2)************Mega menue style******** */

.navbar-brand {
  font-family: "Lalezar-Regular";
  font-size: 30px;
  color: rgb(206, 181, 35);
}
.navbar-brand2 {
  font-family: "Lalezar-Regular";
  font-size: 20px;
  color: rgb(63, 43, 28);

  margin: 0.75rem;
}
.navbar-brand2:hover {
  color: rgb(250, 245, 200);
  text-shadow: 0px 0px 15px var(--brownPink);
}
.footer-brand {
  font-size: 50px;
  font-family: "Lalezar-Regular";
  /* color: rgb(222, 167, 28); */
  background: -webkit-linear-gradient(right, rgb(218, 158, 7), #333);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footer-brand:hover {
  background: -webkit-linear-gradient(left, rgb(218, 158, 7), #333);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.navbar .footer-brand {
  font-size: 30px;
  font-style: italic;
}
.gradient-text:hover {
  animation: rainbow-text-simple-animation 0.5s ease-in forwards;
}

/* Move the background and make it smaller. */
/* Animation shown when entering the page and after the hover animation. */
@keyframes rainbow-text-simple-animation-rev {
  0% {
    background-size: 650%;
  }
  40% {
    background-size: 650%;
  }
  100% {
    background-size: 100%;
  }
}

/* Move the background and make it larger. */
/* Animation shown when hovering over the text. */
@keyframes rainbow-text-simple-animation {
  0% {
    background-size: 100%;
  }
  80% {
    background-size: 650%;
  }
  100% {
    background-size: 650%;
  }
}

/* Style the rest of the page. */

header {
  margin-top: 1em;
  margin-top: calc(50vh - 3em);
}

.navbar .megamenu {
  padding: 1rem;
}
/* ============ desktop view ============ */
@media all and (min-width: 768px) {
  .navbar .has-megamenu {
    position: static !important;
   
  }

  .navbar .megamenu {
    left: 0;
    right: 5%;
    width: 90%;
    margin-top: 0;
    border-radius: 1rem;
    max-height: 600px;
    overflow-y: auto;
  }
}

.megamenu-icon {
  width: 20px ‌‌ !important;
  height: 20px !important;
}
.btn-size {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgb(128, 94, 73);
  border-radius: 0;
  text-align: start;
  color: rgb(89, 52, 41) !important;
  margin-bottom: 1rem;
  line-height: 30px;
  position: relative;
  top: 0;
  transition: all ease 0.3s;
}
.btn-size:hover {
  border-bottom: 3px solid rgb(205, 72, 24);
  color: rgb(167, 77, 50) !important;
  font-size: 103%;
  line-height: 30px;
  text-shadow: 2px 2px 25px #151312;
  /* top: -5px; */
  /* left: -2px; */
  transform: scale(1.07);
  box-shadow: 0 15px 10px -10px rgb(104, 74, 48);
  text-decoration: none;
  -webkit-text-decoration: none;
}

.megamenu-img-text {
  font-size: 15px;
  position: absolute;
  bottom: 10px;
}
.btn-mega {
  background-color: #1d1815;
}
.mega-menu-subtitle {
  font-size: 92%;
  color: #6b6058;
  text-decoration: none;
  line-height: 27px;

  transition: all 0.1s ease-in-out;
}

.mega-menu-subtitle a:hover {
  border-bottom: 1px solid rgb(205, 72, 24) !important;
  text-shadow: 0px 0px 25px #171402;
  transform: scale(1.05) !important;
  /* box-shadow: 0 10px 6px -6px rgb(104, 74, 48) ; */
  color: #f26d07;
  transition: all 0.1s ease-in-out;
}
.megamenu ul li a {
  /* color: red; */
  display: none;
}

.megamenu input[type="checkbox"] {
  display: none;
}
.megamenu ul li a {
  /* color:green; */
  display: inline !important;
}
.megamenu ul li a:hover {
  border-bottom: 1px solid var(--brownOrange) !important;
  text-shadow: 0px 0px 25px #443c11;
  transform: scale(1.05);
  /* box-shadow: 0 10px 6px -6px rgb(104, 74, 48) ; */
  color: #f26d07 !important;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
  -webkit-text-decoration: none;
}
.menu-drop {
  cursor: pointer;

  width: 25px;
  height: 25px;
  padding: 10px;
  /* margin-top: -50px; */
  color: rgb(109, 96, 66);
  font-weight: bold;
  font-size: 12px;
  border-radius: 50%;
  /* border-radius: 0 3px 3px 0; */
  user-select: none;
  -webkit-user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  transition: all 0.5s ease-in-out;
}
.menu-drop:hover {
  background-color: rgba(140, 130, 103, 0.3);
  color: beige;

  transition: all 0.3s ease-in-out;
}

.form-check-input-checked-color {
  color: #000;
}

.accordion-categories a:hover {
  border-bottom: 1px solid rgb(118, 118, 15) !important;
  text-shadow: 0px 0px 35px #91918d;
  transform: scale(1.05) !important;
  /* box-shadow: 0 10px 6px -6px rgb(104, 74, 48) ; */
  color: rgb(136, 110, 18);
}
.accordion-categories {
  /* font-size: 95%;  */
  color: #6b6058;
  text-decoration: none;
  line-height: 32px;

  transition: all 0.1s;
}

.dropdown-item1 {
  font-size: 12.5px;
}
/*  */

.list-unstyled {
  display: inline;
}
/* ***hover dropdown menue**** */

.dropdown-menu2 {
  display: none;
  margin-top: 1rem;
  transition: all 0.3s ease-in;
  /* delay: .5s; */
  /* // remove the gap so it doesn't close */
}
.dropdown2:hover .dropdown-menu {
  display: block;
  margin-top: 0;
  visibility: visible;
  opacity: 1;
  /* delay: .5s; */
  /* // remove the gap so it doesn't close */
}
/* .nav-link:focus {
  color: red !important; 
  delay: .5s;
remove the gap so it doesn't close 
} */
.user-tooltip {
  color: #fadaa3;
  font-weight: bold;
  font-style: italic;
  /* font-size: 14px; */
  border-bottom: 1px solid #d8b171;
}
.dropdown:focus .dropdown-menu {
  visibility: hidden;
  opacity: 0;
  color: red !important;
  /* margin-top: 1rem; */
  /* delay: .5s; */
  /* // remove the gap so it doesn't close */
}
.dropdown-toggle::after {
  display: none;
}
.col-megamenu {
  font-size: 14px;
}

.bag:hover .offcanvas {
  display: block;
  margin-top: 0;
}
.cart-icon {
  font-size: 28px;
  color: rgb(219, 214, 176) !important;
}
.Business-cover-icon {
  color: rgb(239, 226, 166);
  font-size: 15px;
}

.cart-icon:hover {
  color: rgba(212, 200, 108, 0.931) !important;
}
.cart-nav {
  position: relative;
  top: 3px;
}

.cart-count {
  color: var(--darkBrown) !important;
  font-size: 12px;
  /* font-weight: bold; */
  position: absolute;
  top: -5px;
  right: 5px;
  width: 22px;
  height: 19px;
  background: rgb(242, 231, 149);
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
}
.metademy-en .cart-count{
  left: 5px !important;
}
.cart-count:hover {
  background: rgba(209, 194, 79, 0.931);
}
.items-count {
  color: var(--brownGray) !important;
  font-size: 12px;
  width: 22px;
  height: 22px;
  background: rgb(224, 220, 189);
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
}

.Nav-Province {
  font-size: 14px;
  /* font-weight: bold; */
  font-style: italic;
}
.icon-country {
  font-size: 30px;
  position: relative;
  font-weight: bold;
  vertical-align: middle;
}

.carousel_blog {
  width: 100%;
  height: auto;
}

.my-header {
  position: relative;
  z-index: 1;
  height: AUTO;
  padding-bottom: 100px;
}
.flip-y-180 {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.fixed-bg1 {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-image: linear-gradient(to right,#8078718a, rgba(110, 101, 90, 0.2)) , url("../public/img/pexels-kelly-leonard-5770637.jpg"); */
  background-image: linear-gradient(to top, #543f2e8a, rgba(110, 101, 90, 0)),
    url(../public/img/homePage/lottory.png);
  min-height: 800px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  opacity: 1;
  background-color: var(--khaki);
  /* filter: grayscale(30%); */
  /* mix-blend-mode: soft-light; */
}
.image-234 {
  position: absolute;
  top: 200px;
  z-index: 100;
}

.header-firstpage {
  /* padding-top: 5rem; */
  color: #f7f3f2;
  text-shadow: 0px 0px 25px #ecea5e;
  font-family: "Lalezar-Regular";
}
.bg-home {
  background: rgb(217, 218, 220);
  background: linear-gradient(
    45deg,
    rgba(217, 218, 220, 1) 0%,
    rgba(215, 206, 195, 1) 7%,
    rgba(183, 171, 157, 1) 27%,
    rgba(150, 134, 116, 1) 52%,
    rgba(115, 95, 76, 1) 78%
  );
  position: relative;
}

.bg-bus {
  /* background: rgb(215,207,193);
  background: linear-gradient(333deg, rgba(215,207,193,1) 0%, rgba(162,147,122,1) 17%, rgba(88,81,46,1) 35%, rgba(65,63,24,1) 72%, rgba(10,9,8,1) 88%); */
  position: relative;
}
.overlay-b {
  position: absolute;
  bottom: 0px;
  z-index: -1;
}
.overlay-b2 {
  position: absolute;
  bottom: 0px;
  z-index: 1;
}

.titr1 {
  font-family: "Lalezar-Regular";
  color: rgb(214, 189, 143);
  font-size: 5em;
  font-style: normal !important;
  /* font-weight: 500; */
  /* text-decoration: underline white; */
  text-shadow: -5px 2px 0 rgba(65, 60, 49, 0.597);
  /* user-select: none; */
  text-align: center;
  font-stretch: ultra-condensed;
}
@media (max-width: 768px) {
  .titr1 {
    font-size: 4.5em;
  }}

.titr3 {
  /* font-family: 'Optimistic Display','NotoSans','NotoSansJP','NotoSansKR',sans-serif; */
  font-size: 1em;
  font-weight: 100;
  color: rgb(227, 214, 185);
  text-align: center;
  font-family: vazir;
  /* text-shadow:0px 0px 25px #783f19  */
  /* line-height: 1.8; */

  /* 1.8<line-height<2  for farsi text*/
}
.titr2 {
  /* font-family: "Optimistic Display", "NotoSans", "NotoSansJP", "NotoSansKR", */
  /* sans-serif; */

  direction: ltr;
  font-size: 85px;

  font-stretch: condensed;
  color: rgb(247, 245, 235);
  margin-left: 0;
  padding-left: 1;
  text-decoration: none;
  max-height: 115px;
}
@media (max-width: 768px) {
  .titr2 {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .titr2plus {
    font-size: 50px;
  }
}

.titr4 {
  font-family: comic Sans;
  direction: ltr;
  font-size: 40px;
  padding: 0;
  margin: 0;
  margin-top: -4rem;
  color: rgba(223, 186, 112, 0.913);
  display: block;
  /* -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg); */
  position: relative;
  top: 7px;
  right: -110px;
  z-index: 10;
}
@media (max-width: 768px) {
  .titr4 {
    font-size: 30px;
    top: 14px;
    right: -45px;
  }
}

.titr5 {
  font-family: "Lalezar-Regular";
  color: var(--brownGray);
  font-size: 22px;
  font-style: normal !important;
  /* font-weight: 500; */
  /* text-decoration: underline white; */
  /* text-shadow: -5px 2px 0 rgba(65, 60, 49, 0.597); */
  /* user-select: none; */
  text-align: center;
  font-stretch: ultra-condensed;
}
.titr6 {
  font-size: 18px;
  font-weight: bold;
  color: var(--brownGray);
  font-stretch: extra-condensed;
  
}
.z-top{
  z-index: 1;
}
.titr7 {
  font-family: "Lalezar-Regular";
  color: var(--brownGray);
  font-size: 30px;
  font-style: normal !important;
  text-align: center;
  font-stretch: ultra-condensed;
}
.titr7-5{
  font-size: 20px;
}
@media (max-width: 768px) {
  .titr7-5{
  font-size: 16px;
}}
@media (max-width: 768px) {
  .titr7-25{
  font-size: 25px;
}}

.titr8{
  position: absolute;
  top: 15px;
  right: 180px;
  z-index: 10;
  background-color: var(--golbehi);
  padding: 3px 10px;
  border-radius: 3px;
  color: var(--brown2);
}
.titr8-5{
  right: 170px;
  padding: 3px 7px;
}
.titr9{
  font-family: comic Sans;
  color: rgb(161, 141, 76);
  font-size: 30px;
  font-style: normal !important;
  text-align: center;
  font-stretch: ultra-condensed;
 top: 10px;
  position: absolute;

}

.fixed-bg2 {
  background-image: url("../public/img/abstract_natural_background_hand_draw_generated.jpg");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: saturate(30%);
}

/*   
  social media.................................
   */
.backtotop {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
}
@media only screen and (max-width: 768px) {
  .backtotop {
    bottom: 4rem;
  }
}

#mr ul li {
  list-style: none;
  padding: 3px;
}

#mr ul li a {
 
  width: 35px;
  height: 35px;
  background-color: #e6e6e6;
  text-align: center;
  line-height: 35px;
  font-size: 20px;
  margin: 0 1px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 2px solid #e6e6e6;
  z-index: 1;
}

#mr ul li a .icon {
  position: relative;
  color: #503e34;
  transition: 1s;
  z-index: 3;
  display: block;
}

#mr ul li a:hover .icon {
  display: block;
  color: #e6e6e6;
  transform: rotatey(360deg);
}
/* #mr ul li a:hover .icon {
    display: block;
  color: #e6e6e6;
 background-color:#503e34 
   ;
} */
#mr ul li a:hover {
  background-color: transparent;
}

#mr ul li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: #f00; */
  background: rgb(173, 151, 98);

  transition: 1s;
  z-index: 2;
}

#mr ul li a:hover:before {
  top: 0;
}
/* 
#mr ul li:nth-child(1) a:before {
  background: #3b5999;
}

#mr ul li:nth-child(2) a:before {
  background: #55acee;
}

#mr ul li:nth-child(3) a:before {
  background: #0077b5;
}

#mr ul li:nth-child(4) a:before {
  background: #dd4b39;
}
#mr ul li:nth-child(4) a:before {
  background: #FF0000;
} */
.or-log {
  width: 25px;
  height: 25px;
  border: 1px solid rgb(131, 129, 129);
  border-radius: 50%;
  color: #525252;
  padding-left: 6px;
  padding-right: 6px;
}
.line-log {
  color: #716e6e;
}
.myfooter {
  /* height: 300px; */
  width: 100%;
  color: var(--khaki);
  margin: 0;
  padding: 0;

  background: -webkit-linear-gradient(top, #a99282, #1d1815) !important;
  /* background: -o-linear-gradient(top, #a99282, #1d1815)!important; */
  /* background: -moz-linear-gradient(top, #a99282, #1d1815)!important; */
  /* background: linear-gradient(top, #a99282, #1d1815)!important; */
}
.myfooter .copyright-line a {
  color: var(--khaki);
}
.myfooter .copyright-line a:hover {
  color: var(--beige);
  text-shadow: 0px 0px 15px var(--brownPink);
}
.container {
  margin: 0;
  max-width: revert;
  padding: 0;
}

/*   end of social media.................................*/

.container-contact1 {
  width: 1163px;
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
  overflow: hidden;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 90px 130px 88px 148px;
}

/*------------------------------------------------------------------
  [  ]*/
.contact1-pic {
  width: 150px;
}

.contact1-pic img {
  max-width: 100%;
}

/*------------------------------------------------------------------
  [  ]*/
.contact1-form {
  width: 390px;
}

.contact1-form-title {
  display: block;

  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 44px;
}

input.input1 {
  height: 50px;
  border-radius: 25px;
  padding: 0 30px;
}
input.input1 + .shadow-input1 {
  border-radius: 25px;
}

textarea.input1 {
  min-height: 150px;
  border-radius: 25px;
  padding: 12px 30px;
}
textarea.input1 + .shadow-input1 {
  border-radius: 25px;
}

/*---------------------------------------------*/
.wrap-input1 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 20px;
}

.input1 {
  display: block;
  width: 100%;
  background: #e6e6e6;

  font-size: 15px;
  line-height: 1.5;
  color: #666666;
}

.shadow-input1 {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(187, 109, 25, 0.5);
}

.input1:focus + .shadow-input1 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 80px 30px;
    opacity: 0;
  }
}

/* login form /////////////////////////////////////////////////*/
/* tranparency of modal background  */
.modal-backdrop {
  background-color: rgba(45, 34, 12, 0.6);
  z-index: 1000;
}
.modal-body{
  z-index: 1030 !important;
}
.input:autofill {
  background-color: var(--brownOrange) !important;
}
.modal {
  height: 100vh;
  /* text-align: center */
  color: var(--darkBrown);
 
}
.modal .login-btn:hover {
  background-color: var(--brownGray);
  border-color: var(--brownGray);
  text-decoration: none;
  color: rgb(247, 243, 243);
  transition: all 500ms linear;
}
.forget-pass {
  font-size: smaller;
  font-style: italic;
  font-stretch: expanded;
  padding: 5px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}
.forget-pass:hover {
  color: rgb(141, 122, 13);
  /* text-shadow: 2px 2px 15px #f0f805 ; */
  background-color: #72786539;
  border-radius: 20px;
}
.ql-snow .ql-editor img {
  width: 100% !important;
}
.container-404 {
  padding: 5rem;
  padding-top: 0;
  margin-top: 0;
  /* position: absolute; */
  height: 100vh;
}
.img-404 {
  max-height: 70vh;
}
.btn-404 {
  max-width: 300px;
  min-width: 150px;
  /* width: 50px; */
}
/*Trigger Button*/
.login-trigger-user {
  color: rgb(219, 214, 176) !important;
  background: transparent;
  /* padding: 5px 10px; */
  /* border-radius: 20px; */
  border-color: transparent;
  line-height: 15px;
  position: relative;
  /* top: 50%; */
}

.login-trigger:hover {
  background: #857b74;
  color: #fff;
  border: 2px solid #4e4845;
}
.login-trigger-user:hover, .login-trigger-user:focus-visible , .login-trigger-user:focus, .login-trigger-user:visited, .login-trigger-user:active {
  color: rgb(157, 143, 35) !important;
  text-shadow: rgba(199, 172, 117, 0.769) 0px 0px 10px 5px;
    background: transparent !important;
}
.active {
  border: none !important;
}
/*Trigger Button*/
.login-trigger2 {
  font-weight: bold;
  /* font-size: 1.1em; */
  color: rgb(174, 187, 93);
  background: transparent;
  padding: 0;
  border: none;
  border-color: transparent;
  margin: 0;
  position: relative;
  /* top: 50%; */
}
.login-trigger2:hover {
  background: transparent;
  color: rgb(122, 131, 61);
  /* border: 2px solid #4e4845 */
  /* zoom: 1.1; */
}
.login-trigger3 {
  font-weight: bold;
  /* font-size: 1.8em; */
  color: rgb(192, 91, 69);
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  border-color: transparent;

  position: relative;
  /* top: 50%; */
}
.login-trigger3:hover {
  color: rgb(131, 62, 47);
  background: transparent;
}
.accordion-button4 {
  color: #e8dad3;
  background-color: rgba(97, 75, 61, 0.721);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  border-color: rgba(97, 75, 61, 0.721);
}

.accordion-button4:not(.collapsed) {
  color: rgba(97, 75, 61, 0.954);
  background-color: #b8aea8;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button--bus-moreinfo {
  color: var(--beige);
  background-color: rgba(157, 142, 117, 1);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  margin-bottom: 10px;
}
.accordion-button--bus-moreinfo:not(.collapsed) {
  color: rgba(97, 75, 61, 0.954);
  background-color: var(--beige);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button--bus-moreinfo:not(.collapsed)::after {
  filter: grayscale(100%);
}
.form-check-moreinfo {
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
}
.box1 {
  border: none;
  padding: 2rem;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid #857b74 !important;
  padding-left: 0;

  font-weight: normal;
  background: transparent;
  transition: 0.5s ease-out;
}

.box2 {
  border: none;
  /* padding-top: 0rem; */
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid #857b7490;
  font-weight: normal;
  background: transparent;
  transition: 0.3s ease-out;
}
.box3 {
  border: none;
  /* padding-top: 0rem; */
  border-radius: 20px;
  box-shadow: none;
  border: 2px solid #857b7490;
  font-weight: normal;
  background: transparent;
  transition: 0.3s ease-out;
  margin: 1rem;
}
.box6 {
  border: none;
  padding: 2rem;
  border-radius: 0;
  box-shadow: none;
  /* border-bottom: 2px solid #857b74 !important; */
  padding-left: 0;

  font-weight: normal;
  background: transparent;
  transition: 0.5s ease-out;
}
.show-hide-icon {
  font-size: 22px;
  color: var(--brown2);
  font-weight: bold;
  padding-top: 10px;
}
.form-control:focus {
  box-shadow: none;
  border-color: var(--golbehi);
  /* background: linear-gradient(to LEFT,#e4e1da,#ffffff);/Users/mana/Desktop/Visual Studio Code.app/Contents/Resources/app/out/vs/code/electron-sandbox/workbench/workbench.html */
  border-bottom: 2px solid #4e4845;
  background: transparent;
}
.form-floating .Label-formfloating{
padding-top: 0 !important;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 5.5;
}

@media (max-width: 768px) {
  .blogDashboard .btn-search,
  .blogDashboard .button-filter,
  .blogDashboard .login-btn2,
  .blogDashboard .search-bar-dropdown {
    width: 100% !important;
  }
}
.text-break {
  word-break: break-all;
}
.btn-search {
  border: 1px solid #baaaa2 !important;
  color: #baaaa2 !important;
  background: transparent;
  padding-right: 1rem;
  width: 20rem;
  height: 2rem;
  border-radius: 20px;
  justify-content: center;
  position: relative;
}
.btn-click {
  border: 1px solid #baaaa2 !important;
  /* color: #baaaa2 !important; */
  background: transparent;
  width: 5rem;
  height: 1.5rem;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  vertical-align: center;
  padding-top: 2.5px;
  color: var(--brown2);
  font-size: 10px;
}
.btn-click:hover {
  background: var(--khaki);
  color: var(--beige);
}
.form-input1 {
  background: transparent;
  border: none;
  border: 1.5px dotted rgb(41, 30, 30);
  outline: none;
  width: 75%;
  color: var(--brown2);
}
.o {
  color: #deca7e;
  background-color: #8e9c4a;
}
.form-input2 {
  background: transparent;
  color: rgb(233, 232, 225);
  outline: none;
  border: none;
}
.form-input3 {
  background: transparent;
  color: var(--brown2) !important;
  outline: none;
  border: none;
}
.landingPage-search .form-input3 {
  color: rgb(233, 232, 225);
}
select {
  font-size: 12px;
}
.newsletter {
  outline: none;
  border: none;
  background: rgba(189, 173, 148, 0.057);
  padding-right: 2px;
  align-items: center;
}
.search-box {
  box-shadow: 0 15px 10px 10px rgb(197, 101, 18);
}

.btn-search.bi-search {
  position: absolute;
  /* top: 50%; */
  right: 15px;
  /* color: #c2bd9c */
}

.btn-search:hover,
.btn-search:focus {
  border: 1px solid #c2bfb7 !important;
  box-shadow: rgba(199, 172, 117, 0.769) 0px 0px 10px 5px;
  color: #000;
  transition: 0.5s ease-in-out;
}
/* .backtotop-btn:hover{
  border: 1px solid #c2bfb7 !important;
 text-shadow: rgba(199, 172, 117, 0.769) 0px 0px 10px 5px;} */
 .show-filters {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  border-top: 1px solid #857b7490;
  border-bottom: 1px solid #857b7490;
  color: var(--brownGray) !important;
}
.show-filters .btn-search {
  font-size: 10px;
  width: max-content;
  height: 1.5rem;
  color: var(--brownGray) !important;
}
.show-filters .btn-search:hover {
  background-color: rgb(207, 207, 193);
  transition: 0.3s;
  cursor: pointer;
}
.modal-content {
  /* font-weight: bold; */
  background: linear-gradient(to LEFT, #e4e1da, #ffffff);
  border-radius: 20px;
  box-shadow: rgba(66, 53, 29, 0.235) 0px 0px 10px 10px;
  
}

.modal,
.fade,
.modal-dialog {
  transition: 0.2s ease-in-out;
}
/* .form-control {
    margin: 1em 0;
  } */
.img-modal-prem {
  transform: scale(1.35);
  /* background-color: var(--brown); */
}

.modal-dialog {
  max-width: 800px;
}
.modal-dialog2 {
  max-width: 500px;
}


.form-check-input {
  width: 1.2em;
  height: 1.2em;
}
.form-check-input:checked {
  background-color: var(--brownOrange) !important;
  border-color: var(--brownOrange) !important;
}

.form-check-input-businessForm {
  height: 20px;
  width: 45px !important;
}

/* Forms********************************* */

.form-style {
  font-size: 100%;
  color: #444444;
}

::-webkit-search-cancel-button {
  color: #c2bd9c !important;
}

input::placeholder,
textarea::placeholder {
  color: #c2bd9c !important;
  font-size: .75em !important;

  font-style: italic;
  padding-right: 1rem;

}
input::placeholder .info-btn {
  color: #8a8772 !important;
}

.popover {
  font-family: "vazir" !important;
  border-radius: 5px;
  font-size: small;
}
.popover-body {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin: 0;
  color: #766f65 !important;
}

.addbusiness-btn {
  padding: 2px 20px;
  border-radius: 20px;
  background: #857b74 !important;
  border: 2px solid #857b74 !important;
  color: whitesmoke !important;
  font-weight: bold !important;
  line-height: 15px !important;
  transition: all 0.5s;
  font-size: smaller;
}

.addbusinessform-content {
  /* font-weight: bold; */
  background: linear-gradient(to LEFT, #e4e1da, #ffffff);
  border-radius: 20px;
  box-shadow: rgba(112, 98, 69, 0.235) 0px 0px 10px 10px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 1rem;
}
/* .nav-pills  .nav-link:not(.active){
    
    background:transparent ; 
    border: 2px solid #857b74;
    color: #000;
    
  } */

/* active (faded) */
/* .nav-pills .pill-1 .nav-link {
  background-color: rgba(192, 186, 17, 0.968);
  color: white;
} */

.addbusiness-btn:hover {
  background: whitesmoke !important;
  color: #857b74 !important;
  border: 2px solid #4e4845 !important;
  text-decoration: none;
}
.addbusiness-content {
  /* padding: 5px 15px; */
  /* border-radius: 20px; */
  background: #bdb0a7 !important;
  border: 2px solid #857b74 !important;

  transition: all 0.5s;
  margin-top: 0.6rem;
}

.login-btn {
  padding: 5px 15px;
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  background: none;
  border: 2px solid #857b74;
  color: #857b74;
  font-weight: bold;
  transition: all 0.3s;
  margin-top: 0.6rem;
  /* line-height: 15px;
    font-size: 10px; */
}
.uploaded-img {
  max-width: 100px;
  max-height: 100px;
}
.login-btn0 {
  background: var(--brownOrange);
  margin-top: 10rem;
  border: 2px solid #7b6e55ce;
  color: var(--beige);
  margin: 4px 5px;
}
.login-btn1 {
  background: var(--brownGray);
  margin: 4px 5px;
  border: 2px solid #7b6e55ce;
  color: var(--beige);
}
@media (min-width: 992px) {
  .login-btn1 {
    margin-top: 10rem;
  }
}
@media (max-width: 991px) {
  .login-btn4 {
    font-size: 14px;
  }
}
.login-btn2 {
  background: var(--brownGray) !important;
  color: #e9e0d9 !important;
  border: 2px solid var(--darkBrown) !important;

  /* background: var(--brownOrange);
  margin-top: 10rem;
  border: 2px solid #7b6e55ce;
  color: var(--beige); */
  margin: 4px 5px;
}

.login-btn:hover {
  background: #e6d1ace7 !important;
  color: rgb(137, 132, 124)!important;
  /* border: 2px solid #746d51; */
}
.login-btn:active {
  background: #a9997fe7 !important;
  color: #fff !important;
  /* border: 2px solid #746d51!important; */
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  color: #857b74;
  background: none;
  border: 2px solid #857b74;
  border-radius: 20px;
  font-weight: bold;
}

.nav-pills .nav-link.active {
  color: #fff;
  background-color: #6c5a3ee7;
}
.panel-tab {
  background: none;

  padding: 5px;
}
.panel-tab.active {
  color: #545234 !important;
 
  border-bottom: 3px solid #91907b !important;
}
.panel-tab:hover{
  color: #545234bc !important;
transition: all 0.5s linear ;
  border-bottom: 1px solid #91907bd6 !important;
  background-color:#e6e4c4d6 !important ;
}
.panel-tab0 {
  background-color:#ebead9d6 !important ;
width: fit-content;
  padding: 3px;
/* overflow: hidden; */
border-radius: 10px;

}
.panel-tab2 {
 
  padding: 3px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 1px;
  
}
.panel-tab2.active {
  color: #f5ee90 !important;
  background-color:#413f10d6 !important ;
  border-radius: 10px;
 padding-left: 20px;
  padding-right: 20px;
}
.panel-tab2:hover{
  color: #545234bc !important;
  background-color:#d6d5b6d6 !important ;
transition: all 0.5s linear ; 
border-radius: 10px;
padding-left: 20px;
  padding-right: 20px;
}

.img-login {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.img-login:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.2);
  box-shadow: 0 0 25px 10 rgba(186, 157, 41, 0.2);
  filter: brightness(1.25);
}
.offcanvas-countryLanguage {
  font-size: 14px;
}
.offcanvas-countryLanguageTitle {
  font-size: 14px;
  font-weight: 900;
  background-color: #e5d9cd;
  /* margin-bottom: 5rem; */
}
/* ***************  from theme    ****************** */
/* .form-control{
  border-bottom: 2px solid #857b74 !important;
} */

.form-select1 {
  font-size: 15px;
  color: rgb(80, 56, 47);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid rgb(84, 84, 58);
  background-color: transparent;
}
.form-select2 {
  font-size: 15px;
  color: rgb(80, 56, 47);

  background-color: transparent;
  border-style: dashed;
}
/* ****************************************************  */

.bg-soft-primary {
  background-color: #efebfd !important;
}

.bg-soft-secondary {
  background-color: #f4eca6 !important;
}

.bg-soft-success {
  background-color: #f0b893 !important;
}

.bg-soft-info {
  background-color: #f5f0fc !important;
}

.bg-soft-warning {
  background-color: #fff8ed !important;
}

.bg-soft-danger {
  background-color: #feeff8 !important;
}

.bg-soft-light {
  background-color: #feffff !important;
}

.bg-soft-dark {
  background-color: #e7e8e9 !important;
}


.rounded-4 {
  border-radius: 0.5rem !important;
}

.rounded-5 {
  border-radius: 1rem !important;
}
.rounded-6 {
  border-radius: 2rem !important;
}
.rounded-7 {
  border-radius: 3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

/*-----------------------------------------------
|   Backgrounds
-----------------------------------------------*/
.bg-holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0;
}

.bg-holder.bg-right {
  left: auto;
  right: 0;
}

.bg-holder.overlay:before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.25);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg-holder.overlay-0:before {
  background: rgba(0, 0, 0, 0.7);
}

.bg-holder.overlay-1:before {
  background: rgba(0, 0, 0, 0.55);
}

.bg-holder.overlay-2:before {
  background: rgba(0, 0, 0, 0.4);
}

.bg-holder .bg-video {
  position: absolute;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  min-width: 100%;
}

.bg-holder .bg-youtube {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.bg-light-gradient {
  background: -o-linear-gradient(
    top,
    rgba(236, 240, 253, 0) 0%,
    rgba(236, 240, 253, 0.53) 14.32%,
    #ecf0fd 45.83%,
    rgba(236, 240, 253, 0.43) 84.33%,
    rgba(236, 240, 253, 0) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(236, 240, 253, 0) 0%,
    rgba(236, 240, 253, 0.53) 14.32%,
    #ecf0fd 45.83%,
    rgba(236, 240, 253, 0.43) 84.33%,
    rgba(236, 240, 253, 0) 100%
  );
}

/* -------------------------------------------------------------------------- */
/*                                   Borders                                  */
/* -------------------------------------------------------------------------- */
.overflow-hidden[className*="rounded"] {
  -webkit-mask-image: radial-gradient(#fff, #000);
  mask-image: radial-gradient(#fff, #000);
}

.border-top-2 {
  border-top-width: 0.125rem !important;
}

.border-end-2 {
  border-right-width: 0.125rem !important;
}

.border-bottom-2 {
  border-bottom-width: 0.125rem !important;
}

.border-start-2 {
  border-left-width: 0.125rem !important;
}

.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}

.d-lg-block {
  display: block !important;
}
.d-lg-none {
  display: none !important;
}



.card {
  border: 0;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f7f5f3;
  background-clip: border-box;
  /* border: 2px solid rgba(0, 0, 0, 0.125); */
  /* border-radius: 0.625rem; */
}

.card {
  border: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

/* .card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.625rem - 2px);
  border-top-right-radius: calc(0.625rem - 2px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.625rem - 2px);
  border-bottom-left-radius: calc(0.625rem - 2px);
} */

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem /* rtl:ignore */;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}

/* .card-header:first-child {
  border-radius: calc(0.625rem - 2px) calc(0.625rem - 2px) 0 0;
} */

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 2px solid rgba(0, 0, 0, 0.125);
  border-top: none;
  background: none;
}
.backside .card-footer {
  height: 61px;
}

/* .card-footer:last-child {
  border-radius: 0 0 calc(0.625rem - 2px) calc(0.625rem - 2px);
} */

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

/* .card-img,
.card-img-top {
  border-top-left-radius: calc(0.625rem - 2px);
  border-top-right-radius: calc(0.625rem - 2px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.625rem - 2px);
  border-bottom-left-radius: calc(0.625rem - 2px);
} */

/* ******BODY1********* */

.body1 {
  /* background-color:#e2d9cd; */
  margin-top: 23.43rem;
  padding: 0;
}
.body1 a {
  text-decoration: none;
}
.wrapper {
  margin: 10vh;
}
h1, h2, h3, h4, h5{
  color: var(--darkBrown);
}
.card2 {
  min-height: 250px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    min-height: 200px;
  }

  @media (max-width: 420px) {
    min-height: 150px;
  }
}
.card3 {
  width: 200px;
  height: 200px;
  background-color: rgba(170, 145, 113, 0.26);
  border-radius: 50%;
  padding: 30px;
}
@media (max-width: 768px) {
  .card3-5{
    width: 170px;
    height: 170px;
  }}
  
.card3:hover , .flag:hover {
  filter: brightness(1.2);
  transition: all 0.5s ease-in-out;
  background-color: rgb(185, 179, 171);
}

.card-img-overlay2 {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  background: rgb(104, 109, 35);
  background: linear-gradient(0deg, rgba(114, 75, 4, 0.5) 0%, #c8ad7e 100%);
}

.card-meta {
  color: orange;
}

/* ************ section 2 first page ********************** */

****services***** .content-section {
  padding: 7.5rem;
}

.content-section-heading h2,
.content-section-heading .h2 {
  font-size: 3rem;
}
.content-section-heading h3,
.content-section-heading .h3 {
  font-size: 1rem;
  /* text-transform: uppercase; */
}

.service-icon {
  background-color: #fff;
  color: #1d809f;
  height: 9rem;
  width: 9rem;
  display: block;
  line-height: 7.5rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}
.rounded-circle {
  border-radius: 50% !important;
}
.text-faded {
  color: rgba(255, 255, 255, 0.7);
}
.bag-primary {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

/* ***** Portfolio********* */

.portfolio-item {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem;
}
.portfolio-item .caption {
  display: flex;
  height: 100%;
  width: 100%;
  /* background-color: rgba(33, 37, 41, 0.2); */
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.portfolio-item .caption .caption-content {
  color: #fff;
  margin: auto 2rem 2rem;
}
.portfolio-item .caption .caption-content h2,
.portfolio-item .caption .caption-content .h2 {
  font-size: 0.8rem;
  text-transform: uppercase;
}
.portfolio-item .caption .caption-content p {
  font-weight: 300;
  font-size: 16px;
}
@media (min-width: 992px) {
  .portfolio-item {
    max-width: none;
    margin: 0;
  }
  .portfolio-item .caption {
    transition: background-color 0.7s, -webkit-clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out, background-color 0.7s;
    transition: clip-path 0.25s ease-out, background-color 0.7s,
      -webkit-clip-path 0.25s ease-out;
    -webkit-clip-path: inset(0px);
    clip-path: inset(0px);
  }
  .portfolio-item .caption .caption-content {
    transition: opacity 0.25s;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    /* text-align: center; */
  }
  .portfolio-item img {
    transition: -webkit-clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out;
    transition: clip-path 0.25s ease-out, -webkit-clip-path 0.25s ease-out;
    -webkit-clip-path: inset(-1px);
    clip-path: inset(-1px);
    padding: 10px !important;
  }
  .portfolio-item:hover img {
    -webkit-clip-path: inset(2rem);
    clip-path: inset(2rem);
  }
  .portfolio-item:hover .caption {
    /* background-color: rgba(29, 128, 159, 0.9); */
    background-color: var(--brownOrange);
    -webkit-clip-path: inset(2rem);
    clip-path: inset(2rem);
  }
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

/* ************ end of section 2 first page ********************** */

/* ************ section 3 first page ********************** */
/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumb {
  color: var(--brownGray);
  font-size: 12px;
}
.breadcrumb a {
  color: var(--brownGray);
}
.breadcrumb a:hover {
  color: var(--khaki);
}

/* ************ end of section 3 first page ********************** */

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.solid-card {
  background-color: #b39a74ac;
  height: 150px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  padding: 50px 30px 50px 30px;
  border-radius: 10px;
  color: white;
  font-size: 25px;
  font-family: Lalezar-Regular;
  position: relative;
}
.solid-card a {
  color: #ebe6dd;
}
/* .solid-card2{
  background-color: #8f6f33;
  
  height: 50px;
   
  } */

.blog-img {
  border-radius: 5px;

  /* height: 230px; */
}
.blog-img:hover {
  cursor: pointer;
  filter: saturate(0.3);
}
.blog-search {
  width: 200px;
}
.blog .card-footer .icon-stack,
.blog .card-footer .button-filter {
  font-size: 10px;
}
.blog .dropdown .button-filter {
  font-size: 11px;
  height: 1.6rem;
}

.arrow2 {
  width: 33px !important;
}
.arrow3 {
  width: 25px !important;
}
.arrow3:hover {
  /* background-color: #7e7672; */
  /* border-radius: 50%; */
  filter: drop-shadow(0 0 10px black);
  cursor: pointer;
}
.arrow4 {
  width: 40px !important;
}
.arrow5 {
  width: 50px !important;
}

.nolinelink a {
  text-decoration-line: none;
}
.links a:hover {
  font-size: 105%;
  letter-spacing: 0.08rem;
}
.blog {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
}
.jobCrad-img {
  width: 70px !important;
  height: 70px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}
.jobCrad-img2 {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}
.jobCrad-img3 {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}

.error-message {
  color: var(--darkBrown);
  font-size: 11px;
  font-style: italic;
  margin-top: 10px;
  background-color: rgba(236, 126, 79, 0.233);
  border-right: 3px solid rgba(243, 74, 71, 0.947);
  
  
}
.error-red-border {
  border: 2px solid rgba(243, 74, 71, 0.865) !important;
  border-radius: 5px !important;
  box-shadow: 0px 5px 10px rgba(220, 86, 45, 0.681) !important;
}
.error-icon {
  color: rgba(220, 86, 45, 0.681);
  font-size: 14px;
}
.entry {
  padding: 30px;
  margin-bottom: 50px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: rgb(255, 254, 254);
  text-align: justify;
}
.blog .entry2 {
  padding: 15px;
  margin-bottom: 0px;
  box-shadow: none;
  border-radius: 0;
  background-color: rgb(255, 254, 254);
}
.blog .entry3 {
  padding: 0px;
  margin-bottom: 15px;
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;
}
.blog .entry .entry-title {
  font-size: 20px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
  color: var(--darkBrown);
  letter-spacing: -0.5px;
}

.similar-blog {
  max-width: 1400px;
  width: 100%;
}
.blog .similar-blog .entry-title {
  font-size: 15px;
  font-weight: bold;
  padding: 0;
  margin: 10px 0 10px 0;
  color: var(--darkBrown);
  align-content: center;
  text-align: justify;
}
.blog .sidebar-all .entry-title {
  font-size: 14px;
  font-weight: normal;
  padding: 0;
  margin: 5px 0 0 0;
}
.video-frame-sidebar {
  border-radius: 10px;
}
.blog .entry .entry-title {
  color: #493c3e;
  transition: all 0.3s ease-in-out;
}
.blog .entry .entry-title:hover {
  color: var(--brownOrange);
  cursor: pointer;
}
.blog .entry .entry-meta {
  /* margin-bottom: 10px; */
  color: var(--brownGray);
}

.blog-tag {
  color: var(--brownGray);
  border: 1.5px solid #78665c8e;
  border-radius: 5px;
  line-height: 22px;
  font-size: 10px;
  padding: 0 5px 0 5px;
  background-color: var(--beige);
}
.metademy-en .blog-tag{
  font-size: 12px;
}
.blog-tag:hover {
  color: var(--beige);

  background-color: var(--brownGray);
  transition: 0.5s ease-in-out;
}
.prev-next-post {
  font-size: 12.5px !important;
}

.entry-img {
  border-radius: 30px;
}
.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}
.blog .entry .entry-meta ul li {
  font-size: 11px;
  padding: 5px;
  margin-left: 20px;
}
.blog .entry .entry-meta i {
  /* font-size: 12px; */
  margin-left: 8px;
  line-height: 0;
  cursor: pointer;
}
.blog .entry .entry-meta a {
  font-size: 12px;
  display: inline-block;
  line-height: 1;
}
/* .blog .entry .entry-meta i:hover {
 width: 25px;

 color: var(--brownOrange);
 background-color: var(--beige);
 border-radius: 20px;
} */
.blog .entry .entry-meta li:hover,
.blog .entry .entry-meta a:hover {
  color: var(--brownOrange);
}

.blog .entry .entry-content p , .blog .entry .entry-content p span{
  line-height: 24px;
  color: #5e4e45dd !important;
}
/* .blog .entry .entry-content {
  -moz-text-align-last: left;
  text-align-last: left;
} */
.blog .entry .entry-content {
  display: inline-block;
  /* background: #5e4e45dd; */
  color: #5e4e45dd;
  padding: 5px 15px;
  transition: 0.3s;
  font-size: 16px;
  /* border: 1.5px solid  #5e4e45dd ; */
  border-radius: 20px;
}

.blog .entry .entry-content .read-more2,
.read-more3 {
  padding-top: 1rem;
  display: inline-block;
  color: #78731add;
  justify-content: end;
  transition: 0.3s;
  font-size: 12px;
  border: none;
  font-style: italic;
  cursor: pointer;
}
.blog .entry .entry-content:hover {
  color: #5e1d03cf;
}
/* .blog .entry .entry-content:hover {
  background: #5e4e45dd;
  border: 1.5px solid  #5e4e4500 ;
  color:#f4efedcf;
} */
.blog .entry .entry-content .read-more2:hover,
.read-more3:hover {
  background: none;
  border: none;
  color: #98542ef1;

  transform: scale(1.03);
  text-shadow: -5px 2px 0 rgba(203, 182, 136, 0.495);
}
.blog .entry .entry-content .read-more link:hover {
  background: #5e4e45dd;
  border: 1.5px solid #5e4e4500;
  color: #f4efedcf;
}
.blog .entry .entry-content .read-more2 link:hover {
  background: none;
  border: none;
  color: #98542ef1;

  transform: scale(1.03);
  text-shadow: -5px 2px 0 rgba(203, 182, 136, 0.495);
}

.blog-single-title {
  font-size: 35px;
  /* font-weight: bold; */
  font-family: Lalezar-Regular;
  color: var(--darkBrown);
}

.blog-header {
  font-size: 25px;
  color: var(--darkBrown);
}
.blog .entry .entry-content h3 {
  font-size: 20px;
  margin-top: 30px;
  font-weight: bold;
}
.blog .entry-single {
  margin-bottom: 30px;
}
.blog .blog-author,
.blog .similar-blog {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}
.blog .blog-author img {
  width: 90px;
  height: 90px;
}
.blog .blog-author h4 {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
  color: #493c3e;
}
.blog .blog-author .social-links {
  margin: 0 10px 10px 0;
}
.blog .blog-author .social-links a {
  color: rgba(73, 60, 62, 0.5);
  margin-right: 5px;
}
.blog .blog-author p {
  font-style: italic;
  color: #524a47;
}

.recom-head {
  padding-right: 1.5rem;
  font-style: italic;
  font-size: 14px;
  color: rgb(87, 71, 10);
}

.recom-title {
  padding-right: 1.5rem;
  font-style: italic;
  font-size: 16px;
  color: rgb(126, 106, 34) !important;
}
.recom-title:hover {
  color: rgb(210, 174, 44) !important;
  cursor: pointer;
}
.recom-title:hover .recom-head {
  color: rgb(160, 142, 78) !important;
}
.blog .blog-comments img {
  width: 50px;
  height: 50px;
}

.blog .blog-pagination {
  color: #816a6e;
}
.blog .blog-pagination ul {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.blog .blog-pagination li {
  margin: 0 5px;
  transition: 0.3s;
}
.blog .blog-pagination li a {
  color: #493c3e;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  /* height: 400px; */
  /* overflow: auto; */
}
/* .sidebar-all {
  background-color: #524a47;
} */
.blog .sidebar-all .large-img {
  border-radius: 15px;
  height: 445px;
  object-fit: cover;
}
.blog .sidebar-all .small-img {
  border-radius: 5px;
  height: 90px;
  width: 120px;
  /* object-fit: cover; */
  justify-items: center;
  justify-content: center;
}
@media (max-width: 1590px) {
  .blog .sidebar-all .small-img {
    border-radius: 5px;
    height: 75px;
    width: 100px;
    /* object-fit: cover; */
    justify-items: center;
    justify-content: center;
  }
  .blog .sidebar-all .listing-sidebar-section {
    padding: 20px;
  }
}
@media (max-width: 762px) {
  .blog .sidebar-all .small-img {
    border-radius: 5px;
    height: 90px;
    width: 120px;
    /* object-fit: cover; */
    justify-items: center;
    justify-content: center;
  }
  .blog .sidebar-all .listing-sidebar-section {
    padding: 30px ;
  }
}

.css-1ip16uo {
  z-index: 0 !important;
}

.blog .similar-blog .small-img {
  border-radius: 5px;
  padding: 2px;
}

.blog img {
  width: 100%;
  border-radius: 5px;
}
.blog .accordion img {
  width: auto;
  border-radius: 0;
  margin-left: 0.5rem;
  /* margin-right: 1rem; */
}

.blog-post {
  max-height: 1500px;
  overflow: hidden;
  position: relative;
}
.blog-post .content{
  margin-bottom: 7rem;
  /* text-align: center; */
}
/* .blog-post .content p{
 
  text-align: start;
}

@media only screen and (min-width: 768px){
  .blog-post .content img{

width: 75%;
}} */


.blog-post::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(217, 214, 211, 0.692)
  );
}

.read-more {
  display: block;
  width: 100px;
}

.blog-post.expanded {
  max-height: none;
}

.blog-post.expanded .read-more {
  display: none;
}
.blog-post iframe {
  display: block;
  width: 480px;
  height: 270px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.accordion-button-blog {
  color: var(--darkBrown);
  background: none;
  font-size: large;
  font-weight: 600;
}
.accordion-button-blog:not(.collapsed) {
  color: var(--darkBrown);
  background: none;
  box-shadow: none;
}
.accordion-button-blog:hover {
  color: var(--brown);
  transform: scale(1.005);
}

.accordion-button-blog:not(.collapsed)::after {
  filter: grayscale(100%);
}
/* .accordion-button::after {
  margin-right: auto !important;
} */

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #493c3e;
  position: relative;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #aaaaaa;
}
.blog .sidebar .tags {
  margin-bottom: -10px;
}
.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}
.blog .sidebar .tags ul li {
  display: inline-block;
}
.blog .sidebar .tags ul a {
  color: #8e767a;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #f6f4f4;
  display: inline-block;
  transition: 0.3s;
}
.blog .sidebar .tags ul link {
  color: #8e767a;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #f6f4f4;
  display: inline-block;
  transition: 0.3s;
}
.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #e43c5c;
  background: #e43c5c;
}
.blog .sidebar .tags ul link:hover {
  color: #fff;
  border: 1px solid #e43c5c;
  background: #e43c5c;
}
.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #dfd8d9;
  font-size: 14px;
}
.blog .sidebar .tags ul link span {
  padding-left: 5px;
  color: #dfd8d9;
  font-size: 14px;
}

/* ********************* Blog-multi************* */
.section-title {
  margin-bottom: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-right: 5px solid #ffcc00;
}

HTML SCSS JSResult Skip Results Iframe EDIT ON .container {
  width: 50%;
}
/* #map {
  height: 50vh;
  margin-bottom: 10px;
  display: none;
} */

#locationList .card {
  padding: 10px;
}

#toast-container {
  top: 50%;
  bottom: unset;
}
.toast {
  background-color: rgba(0, 0, 0, 0.8);
}

@media only screen and (min-width: 768px) {
  .container3 {
    width: 80%;
    max-width: 800px;
    margin: 0;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

/* *********************upload photo******************** */

.files input {
  font-size: small;
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 30px 0px 55px 20%;
  text-align: center !important;
  margin: 0;
  width: 154px !important;
  height: 104px !important;
  background-color: rgb(252, 252, 247);
}

.files input:focus {
  outline: 2px dashed #20555a;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}
.files input:hover {
  outline: 2px dashed #bea23d;
  outline-offset: -10px;
  background-color: beige;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
}

.files {
  padding: 10px;
  position: relative;
  border-bottom: 0.5px solid var(--khaki);
  /* text-align: center; */
  flex: auto;
}

.files:before {
  position: absolute;
  bottom: 0px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 15px;
  /* content: " می‌توانید عکس را به این قسمت بکشید و رها کنید! "; */
  display: block;
  margin: 0 auto;
  color: #516c67;
  font-weight: 100;
  text-transform: capitalize;
  text-align: center;
  font-size: small;
  line-height: 5;
}

/* ********************* end of upload photo******************** */

/* ****************HERO******************** */
.hero {
  padding: 300px 0;
}

.listing-sidebar-section {
  background: #ffffff;
  -webkit-box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  padding: 30px 30px 20px 30px;
  border-radius: 10px;
}

.listing-sidebar-section h4 {
  color: #333333;
  font-weight: 600;
  margin-bottom: 16px;
}

.listing-sidebar-section ul li {
  font-size: 15px;
  list-style: none;
  color: #5b5039;
  line-height: 32px;
  border-bottom: 1px dotted #eeebe8;
}

/* .listing-sidebar-section ul li span {
  float: left;
} */



.m2 {
  text-decoration: underline;
  text-decoration-color: peru;
  text-underline-offset: 0.6em;
  text-decoration-thickness: 3px;
}
.listing-details-socialmedia {
  font-weight: 300;

  padding: 0;
  align-items: center;
  justify-content: center;
}
.listing-details-socialmedia ul {
  margin: 0;
}
.group-list-item {
  direction: ltr;
  position: relative;
  /* padding-left: 35px; */
  margin-bottom: 20px;
  display: flex;
  color: var(--darkBrown);
}

.group-list-item2 {
  direction: ltr;
  position: relative;
  /* padding-left: 35px; */
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  /* display: flex; */
  color: #444444;
  /* margin-left: auto; */
}
.carousel-item img {
  height: 550px;
  object-fit: cover;
  margin-top: 0;
  padding-top: 0;
}
.special-carousel {
  max-width: 1400px;

  margin: 0 auto !important;
}
ul {
  list-style: none;
  /* display: flex; */
}

.social-icon {
  height: 45px !important;
  width: 45px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(104, 101, 101);
  font-size: 25px !important;
  margin: 0;
  cursor: pointer;
}
.backside .social-icon {
  font-size: 25px !important;
}

.social-twitter:hover {
  color: #55acee;
}

.social-facebook:hover {
  color: #3b5998;
}

.social-telegram:hover {
  color: #00405d;
}

.social-youtube:hover {
  color: #cd201f;
}

.social-instagram:hover {
  color: #dd4b39;
}

.social-pinterest:hover {
  color: #bd081c;
  /* transition: 0.4s all */
}
.social-icon i {
  transition: 0.5s all;
}

.social-icon:hover i {
  transform: scale(1.5);
  text-shadow: 2px 2px 5px rgba(56, 56, 54, 0.851);
  /* color: whitesmoke */
}


/* ******************************end of my listing ************************ */

.item-status {
  color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 18px;
  right: 0;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  position: absolute;
  z-index: 6;
}

.listing-hero {
  padding-top: 70px;
  padding-bottom: 30px;
  background: rgb(186, 167, 141);
  /* background: linear-gradient(90deg, rgba(240,219,157,1) 0%, rgba(168,147,38,1) 54%, rgba(116,95,18,1) 10%); */
  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  /* z-index: 100; */
}

  .tutor-color0 ,  .tutor-color0 a{
    background-color: transparent !important;
    color:  #603535 !important;
    border-color: #603535 !important;
  }
  .tutor-color01{
    background-color: #e7ab3c !important;
  }
  .tutor-color02{
    background-color: #e7ab3c54 !important;
  }
.tutor-color1{
  background-color:#cb997e !important;
}
.tutor-color2{
  background-color:#464610 !important;
  
}
.tutor-color3{
  background-color:#dba802 !important;
}
.tutor-color4{
  background-color:#335520 !important;
  color: beige !important;
}
.tutor-color5{
  background-color: #A48F86 !important;
}
.tutor-color6{
  background-color: #D4B2A7 !important;
}
.tutor-color7{
  background-color: #9d9a7b !important;
}
.tutor-color8{
  background-color: #8E8C89 !important;
}
.tutor-color9{
  background-color: #806B64 !important;
}
.tutor-color10{
  background-color: #D88783 !important;
}

.tutor-color11{
  background-color: #F3B4A5 !important;
}

.tutor-color12{
  background-color: #FBC7BA !important;
}
.tutor-color13{
  background-color: #F7D6C7 !important;
}
.tutor-color13-5{
  background-color: #e2ab99d3 !important;
}
.tutor-color14{
  background-color: #FFE4D3 !important;
}
.tutor-color15{
  background-color: #603535 !important;
}

.tutor-color16{
  background-color: #e9e2d34a !important;
}

.container2 {
  max-width: 1900px;

  padding: 3rem;
}
.bus-bio-bg{
  background-color: hsla(27, 52%, 18%, 0.57);
  border-radius: 10px;
  padding: 10px 15px 1px ;
margin-top: 1rem;
}

/* ******pagation option 1****** */
.pagination-business {
  color: #3e2820;
}
.page-link1:hover {
  color: #50180e;
  background-color: rgba(167, 161, 117, 0.564);
  transition: all 0.1s ease-in-out;
  transform: scale(1.25);
}
.page-link1:active {
  color: #50180e;
  background-color: rgba(167, 161, 117, 0.92);
}
.page-item1 {
  background-color: transparent;
  padding: 0.5rem;
}

.page-link1 {
  color: rgb(70, 48, 32);
  /* border-top-left-radius: .25rem;
border-bottom-left-radius: .25rem; */
  background-color: transparent;
  width: 40px;
  height: 40px;
  text-align: center;
}

.page-item1:last-child .page-link {
  /* border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;} */
  border: none;
}

.page-item1:first-child .page-link {
  /* border-top-right-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem; */
  border: none;
}
.page-item1:not(:first-child) .page-link {
  border-radius: 50%;
  margin-right: 7px;
}
.page-item1:not(:last-child) .page-link {
  border-radius: 50%;
  margin-right: 7px;
}
.card-image1 {
  border-radius: 5px !important;
}

/* ******end of pagation option 1****** */

/* ******pagation option 2****** */
.pagination-layout1 {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.pagination-layout1 li a:hover {
  background-color: #ff4a52;
  color: #ffffff;
  -webkit-box-shadow: 0px 5px 10px 0px #c3c3c3;
  -moz-box-shadow: 0px 5px 10px 0px #c3c3c3;
  box-shadow: 0 5px 10px 0px #c3c3c3;
}
.pagination-layout1 .active a {
  background-color: #ff4a52;
  color: #ffffff;
  -webkit-box-shadow: 0px 5px 10px 0px #c3c3c3;
  -moz-box-shadow: 0px 5px 10px 0px #c3c3c3;
  box-shadow: 0 5px 10px 0px #c3c3c3;
}
.pagination-layout1 li a {
  height: 5rem;
  width: 5rem;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  color: #444444;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 45px;
  -webkit-box-shadow: 0px 4px 10px 0px #dbdbdb;
  -moz-box-shadow: 0px 4px 10px 0px #dbdbdb;
  box-shadow: 0 4px 10px 0px #dbdbdb;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
a:active,
a:hover,
a:focus {
  outline: 0 none;
  color: var(--brownOrange);
}
/* ******end of pagation option 2****** */
.review-text {
  color: rgb(138, 141, 143);
  font-size: 75%;
  font-style: italic;
  margin-right: 0.5rem;
}
.backtotop-btn {
  font-size: 35px;

  color: rgba(94, 84, 66, 0.55);
  margin-right: 30px;
  text-align: center;
}

.ml-4 {
  margin-left: 1rem;
}

.bi-patch-check-fill {
  color: rgb(108, 143, 67);
}

.preview-images-zone {
  width: 100%;
  border: 1px solid #ddd;
  min-height: 180px;
  /* display: flex; */
  padding: 5px 5px 0px 5px;
  position: relative;
  overflow: auto;
}

.preview-images-zone > .preview-image:first-child {
  height: 185px;
  width: 185px;
  position: relative;
  margin-right: 5px;
}
.preview-images-zone > .preview-image {
  height: 90px;
  width: 90px;
  position: relative;
  margin-right: 5px;
  float: left;
  margin-bottom: 5px;
}
.preview-images-zone > .preview-image > .image-zone {
  width: 100%;
  height: 100%;
}
.preview-images-zone > .preview-image > .image-zone > img {
  width: 100%;
  height: 100%;
}
.preview-images-zone > .preview-image > .tools-edit-image {
  position: absolute;
  z-index: 100;
  color: #fff;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  display: none;
}
.preview-images-zone > .preview-image > .image-cancel {
  font-size: 18px;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
  display: none;
  z-index: 100;
}
.preview-image:hover > .image-zone {
  cursor: move;
  opacity: 0.5;
}
.preview-image:hover > .tools-edit-image,
.preview-image:hover > .image-cancel {
  display: block;
}
.ui-sortable-helper {
  width: 90px !important;
  height: 90px !important;
}

.container {
  padding-top: 50px;
}

.reply-btn-comments {
  background-color: white;
  display: inline-block;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  /* vertical-align: middle; */
  transition: all 0.3s ease-in-out;
}
.reply-btn-comments:hover {
  background-color: var(--brownPink);
}
.space-sticky {
  height: 120px;
}
.sticky-top-nav {
  z-index: 1020;
}
.sticky-top{
  /*   overflow-x: hidden; */
  position: -webkit-sticky;
  position:sticky;
  top: 0;
}
.sticky-top2{
  position: fixed;
  /* top: 0; */
  /* right: 0; */
  width: 100%;
}
.sticky-overflow{
overflow-x: hidden;
width: 100vw;
max-width: 100%;
}
.sticky-overflow-none{
  /* overflow-x: visible !important; */
  position: absolute;
  top: 100px;
  width: 100%;
  

}
.Toastify {
  z-index: 1022 !important;
}
#existingBus {
  cursor: pointer;
}

.modal {
  top: 2rem;
}
.modal-tilel-share {
  color: var(--brown2);
}
.btn-close {
  box-shadow: none;
  border: none;
  outline: none;
  transition: transform 0.5s ease-in-out;
}
.btn-close:hover {
  background-color: #b9a79652;
  border-radius: 50%;
  transition: transform 0.5s ease-in-out;
}
.btn-cart {
  color: var(--brown2);
  cursor: pointer;
}
.btn-cart:hover {
  color: var(--brownOrange);

  transition: transform 0.5s ease-in-out;
}

.modal-body .icons {
  margin: 15px 0px 20px 0px;
}

.modal-body .icons a {
  text-decoration: none;
  border: 1px solid transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
  transition: all 0.3s ease-in-out;
}

.modal-body .field {
  margin: 15px 0px -5px 0px;
  height: 45px;
  border: 1px solid #dfdfdf;
  border-radius: 7.5px;
  padding: 0 5px;
}

.field span {
  width: 50px;
  font-size: 1.5rem;
}

.field input {
  border: none;
  outline: none;
  font-size: 0.8rem;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: var(--brownGray);
}

.copy-btn {
  cursor: copy !important;
}
.image-container {
  width: 100%;
  padding-bottom: 75%; /* 4:3 aspect ratio */
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center; /* Aligns content horizontally */
  align-items: center; /* Aligns content vertically */
  /* height: 300px;  */
  /* Set the desired height of the container */
  background-color: transparent;
}
@media (max-width: 1050px) {
  .card2x2 .image-container {
  
    padding-bottom: 100%; /* 1:1 aspect ratio */
  
  }
}
@media (max-width: 768px) {
  .card2x2 .image-container {
  
    padding-bottom: 110%; /* 1:1 aspect ratio */
  
  }
}
@media (max-width: 408px) {
  .card2x2 .image-container {
  
    padding-bottom: 145%; /* 1:1 aspect ratio */
  
  }
}

@media (max-width: 368px) {
  .card2x2 .image-container {
  
    padding-bottom: 200%; /* 1:1 aspect ratio */
  
  }
}



.image-container-bus {
  padding-bottom: 65%; /* 16:9 aspect ratio */
  object-fit: cover;
  cursor: pointer;
}
.image-container-gd {
  padding-bottom: 50%; 
  object-fit: cover;
}
.image-container-gdm {
  padding-bottom: 190%; /* 16:9 aspect ratio */
  object-fit: cover;
}
.image-container-media {
  padding-bottom: 100%; /* 1:1 aspect ratio */
  object-fit: cover;
}
@media (max-width: 768px) {
  .slick-dots {
    display: none !important;
  }
}
.slick-track {
  height: 100% !important;
}

.image-container img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected
  .Mui-selected {
  color: red !important;
}
.clock-register {
  direction: ltr;
}

.cookie-text {
  font-family: vazir !important;
  color: beige;
}
.premium-options-checked {
  border-radius: 16px;
  border-width: 2px;
  border-style: solid;
  height: 80px;
  padding: 15px;
  border-color: var(--brown2);
  background-color: white;
  border-width: 2px;
}

.premium-options {
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;
  height: 80px;
  padding: 15px;
  border-color: rgba(128, 128, 128, 0.539);
  background-color: rgba(245, 245, 245, 0.744);
}
.premium-price {
  font-size: 18px;
  font-weight: bold;
  color: var(--darkBrown);
}
.ads-all {
  max-width: 1400px;
}
.max-w-1000{
  max-width: 1000px;
}
.ad-img {
  vertical-align: bottom;
}
.image-ad-respo {
  width: 100%; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
}

@media (max-width: 768px) {
  .image-ad-respo {
    display: none;
  }
}
.mw-sub-ad {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.ad-calendar {
  max-width: 350px;
  padding: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* border: 3px solid  var(--beige); */
}
.ad-calendar .form-check {
  color: var(--darkBrown);
  background-color: rgba(164, 170, 111, 0.561);
  border-radius: 2px;
  margin-top: 15px;
  font-size: 12.5px;
  padding: 5px;
  height: 40px;
  width: 60px;
  text-align: center;
  vertical-align: middle;
}

.ad-calendar .not-available {
  background-color: rgba(139, 139, 139, 0.196);
  color: #403f3f50;
}
.ad-calendar .pending {
  background-color: var(--khaki);
  color: #403f3f86;
}
.ad-calendar .selected {
  background-color: var(--brownGray);
  color: #403f3f86;
  border: 3px solid var(--beige);
  box-shadow: 4px 4px 10px 4px rgba(166, 166, 130, 0.676);
  color: var(--beige);
}
.ad-calendar .form-check-label {
  vertical-align: middle;
  padding-top: 5px;
}

.ad-calendar .form-check .form-check-input:not(:checked) {
  display: none;
}
.ad-card {
  border: 3px solid var(--beige);
  border-radius: 5px;
}
.ad-no-box {
  width: 45px;
  height: 45px;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
  vertical-align: middle;
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
}
.ad-no-total {
  width: 280px !important;
  height: 50px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-16 {
  font-size: 16px !important;
  
  /* text-align: left */
}
  

.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-25 {
  font-size: 25px !important;
}
.font-30 {
  font-size: 30px !important;
}
.font-40 {
  font-size: 40px !important;
}
.font-45 {
  font-size: 45px !important;
}
.font-50 {
  font-size: 50px !important;
}
.ad-box-title {
  width: 52px;
  height: 30px;

  border-radius: 5px;
  padding: 5px;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 0px;

  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 10px;
  color: rgb(169, 169, 169);
}
.ad-subtitle {
  font-size: 18px;
  font-weight: bold;
  color: var(--brown2);
}
.premium-banner-img {
  /* width: 500px; */
  /* height: 500px; */
  object-fit: cover;
}

.bg-pure-white {
  background-color: rgb(252, 252, 252);
}
.bg-gradient {
  background: -o-linear-gradient(
    top,
    rgba(191, 153, 123, 0.347) 0%,
    rgba(122, 92, 71, 0.53) 14.32%,
    #ecf0fd 45.83%,
    rgba(125, 90, 58, 0.58) 84.33%,
    rgba(213, 165, 110, 0.565) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(191, 153, 123, 0.347) 0%,
    rgba(122, 92, 71, 0.53) 14.32%,
    #ecf0fd 45.83%,
    rgba(125, 90, 58, 0.58) 84.33%,
    rgba(213, 165, 110, 0.565) 100%
  );
}
.bg-body {
  background-color: #f7f5f3 !important;
}
.planx4-active {
  border: 5px solid rgb(255, 179, 0);
}

.MuiDataGrid-toolbarContainer,
.MuiButtonBase-root {
  color: var(--brownGray) !important;
}
.MuiButton-startIcon {
  color: var(--brownOrange) !important;
}
.MuiDataGrid-root {
  font-family: vazir !important;
  font-size: 10px;
}
.css-204u17-MuiDataGrid-main {
  overflow: auto !important;
}
.css-1w5m2wr-MuiDataGrid-virtualScroller {
  overflow: hidden !important;
}
.css-f3jnds-MuiDataGrid-columnHeaders,
.MuiDataGrid-row {
  overflow: visible !important;
}
.slick-slider .slick-arrow {
  display: none !important;
}
.slick-slide {
  height: 100% !important;
}
.card-img-pre {
  width: 100px;
  height: 100px;
}
.max-w-m {
  max-width: 1200px;
}
.max-w-btn {
  max-width: 400px;
}
.max-w-200 {
  max-width: 200px;
}

.ad-select input[type="radio"]:checked ~ label {
  color: #7f8c3e;
  font-weight: bold;
  background-color: var(--beige);
}
/* .ad-select input[type="radio"]{
    display: none;
  } */

::selection {
  background: #cbbb6a90;
  /* color: #fff; */
}

.text-justify-metamarce {
  text-align: justify;
}
.close-login {
  z-index: 1400;
  cursor: pointer;
  position: absolute;
  top: 7px;
  left: 7px;
  width: 30px;
  height: 30px;
  padding: 10px;
  /* margin-top: -50px; */
  color: var(--darkBrown);
  font-weight: bold;
  font-size: 25px;
  border-radius: 50%;
  /* border-radius: 0 3px 3px 0; */
  user-select: none;
  -webkit-user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  transition: transform 0.5s ease-in-out;
  background-color: rgba(5, 4, 1, 0.15);
}

.close-login:hover {
  background-color: rgba(140, 130, 103, 0.5);
  color: beige;

  transition: transform 0.5s ease-in-out;
}
.media .view-cart:hover{
  background-color: var(--beige);
}

.ql-editor {
  text-align: right !important;
  direction: rtl !important;
}
.ql-editor ul {
  display: flex;
  flex-direction: column;  
}

.ql-editor p{
text-align: justify !important;

}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: 0 !important;
}
.ql-editor ul > li::before {
  content: '\2b24'!important;
}
.number-guide{
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #cb996d;
  border: 5px  rgb(247, 244, 237) solid;
  color: #ffffff;
  font-weight: bold;
  font-size: 25px;
  top: -25px;
  right: 10px;
  padding-top: 4px;
  text-align: center;
  position: absolute;
  vertical-align: middle;}
.country-state-city .css-8n8ath-MuiStack-root{
  width: 250px !important;
}
.countdown_bar{
  background: linear-gradient(90deg, rgba(79, 53, 5, 0.999) 0%, rgba(114, 75, 4, 0.5) 60%, #c8ad7e 100%);

  /* background-color:#b8ba34; */
  border-radius: 9px;
  height: 18px;
  
}
.rtl-icon {
  transform: scaleX(-1);
}
/* #accordionExampleCarInfo .accordion-item .accordion-button{
  background-color: var(--warmGrayBeige) !important;
} */