.rightside_main{
    height: 100%;
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
   
    
}
.product{
     width: 250px;
     margin: 30px; 
     
}

.product_image{
  
}
.product_name{
    font-size:16px !important;
    line-height: 24px !important;
    font-weight: 400 !important;

}
.product_rating{
    font-size: 14px;
    color: #007185;
}
.product_price{
    color: red;
    font-weight: bold;
}