/* body,html{
    height: 100%;
    margin: 0;
    background: #7F7FD5;
   background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
    background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
} */

.chat {
  border: none !important;

  box-shadow: rgba(66, 53, 29, 0.235) 0px 0px 10px 10px;
  background: linear-gradient(180deg, #f2f0eb, #ffffff);
  margin-top: 65px;
  margin-bottom: 50px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  /* background: rgb(255,246,229); */
  /* background: linear-gradient(90deg, rgba(255,246,229,1) 9%, rgba(248,171,148,1) 87%, rgba(219,126,92,1) 100%); */
  /* background: rgb(135,162,148); */
  /* background: linear-gradient(90deg, rgba(135,162,148,1) 0%, rgba(143,158,157,1) 15%, rgba(106,117,111,1) 43%, rgba(81,83,75,1) 70%, rgba(77,79,64,1) 100%); */
}
@media (max-width: 576px) {
    .Chat {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
    }}

.metaChat {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.metaChat .card {
  height: 500px;
  border-radius: 15px !important;
  background-color: rgb(255, 255, 255) !important;
  /* color: #2E2562 !important; */
  color: #4d4f40 !important;
}
.metaChat .card-body {
  /* color: #2E2562 !important; */
  color: #4d4f40 !important;
}

.contacts_body {
  overflow-y: auto;
  white-space: nowrap;
}
.msg_card_body {
  overflow-y: auto;
  overflow-x: hidden;
}
.metaChat .card-header {
  border-radius: 10px 10px 0 0 !important;
  border-bottom: 0 !important;
  /* background-color: #FE959A !important; */
  /* background-color: #93A4D8 !important; */
  background-color: #4d4f40 !important;
  color: #ffffff !important;
}
.metaChat .card-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.metaChat .container {
  align-content: center;
}
.metaChat .search {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
}
.metaChat .search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.type_msg {
  /* background-color: #FE959A !important; */
  /* background-color: #93A4D8 !important; */
  background-color: #8f9e9d !important;
  border: 0 !important;
  color: white !important;
  /* height: 50px !important; */
  height: 50px ;
  overflow-y: auto;
}
.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.attach_btn {
  border-radius: 15px 0 0 15px !important;
  /* background-color: #FE959A !important; */
  /* background-color: #93A4D8 !important; */
  background-color: #8f9e9d !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.metaChat .send_btn {
  border-radius: 0 15px 15px 0 !important;
  /* background-color: #FE959A !important; */
  /* background-color: #93A4D8 !important; */
  background-color: #8f9e9d !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.metaChat .search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.contacts {
  list-style: none;
  padding: 0;
}
.contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
.metaChat .contacts .active {
  /* background-color: #93a4d83d !important; */
  background-color: #8f9e9d40 !important;
}
.metaChat .contacts li:hover {
  /* background-color: #93a4d894 !important; */
  background-color: #8f9e9d8c !important;
  transition: transform 0.9s ease-in-out;
}

.user_img {
  height: 50px;
  width: 50px;
  border: 1.5px solid white;
  padding: 2px;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.img_cont {
  position: relative;
  height: 50px;
  width: 50px;
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.metaChat .online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.3em;
  right: 0em;
  border: 1.5px solid white;
}
.metaChat .offline {
  background-color: #c23616 !important;
}
.metaChat .user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.metaChat .user_info span {
  font-size: 16px;
  font-weight: bold;
  /* color: white; */
}
.metaChat .user_info p {
  font-size: 10px;
  /* color: rgba(0, 0, 0, 0.35) !important; */
  color: rgb(164, 162, 162);
}
.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
  margin-right: 50px;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  background-color: #93ab876d;
  padding: 10px;
  position: relative;
  font-size: 16px;
}
.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  margin-left: 50px;
  border-radius: 18px;
  border-bottom-right-radius: 0;
  /* background-color: #FFDDDA; */
  background-color: #f8ab94b3 !important;
  padding: 10px;
  position: relative;
  font-size: 18px;
}
.msg_time {
  /* position: absolute;
left: 0;
bottom: -15px; */
  color: rgba(255, 255, 255, 0.5);
  font-size: 8px;
}
.msg_time_send {
  /* position: absolute; */
  /* right:0;
bottom: -15px; */
  color: rgba(0, 0, 0, 0.35);
  font-size: 10px;
}
.msg_head {
  position: relative;
 
}
#action_menu_btn {
  /* position: absolute;
  left: 10px;
  top: 10px; */
  color: white;
  cursor: pointer;
  font-size: 30px;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i {
  padding-right: 10px;
}
.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.metaChat input::placeholder,
.metaChat textarea::placeholder {
  color: #ffffff !important;
}

.meta-break{
  word-break: break-word;
word-wrap: break-word;
}
