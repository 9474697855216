.featured{
    width: 100%;
display: flex;
    
}
.featuredItem{    
    flex: 1;
    justify-content: space-between;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: -9px 12px 32px 10px rgba(0,0,0,0.3); 
    box-shadow: -9px 12px 32px 10px rgba(0,0,0,0.3);

}

.featuredTitle{
    font-size: 20px;
}

.featuredMoneyContainer{
    margin: 10px 0px;
    display: flex;
}

.featuredMoney{
    font-size: 30px;
    font-weight: 600;
}

.featuredMoneyRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon{
    font-size: 14px;
    margin-left: 5px;
    color: green;    
}
.featuredIcon.negative{
    color: red;

}
.featuredIcon.zero{
    color: rgb(240, 167, 9);

}

.featuredSub{
    font-size: 15px;
    color: gray;
}