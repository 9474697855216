/* .form-container{
    display: flex;
    justify-content: center;
    align-items: flex flex-start;
    height: 100%;
    
}

.form-items{
    display: flex;
    flex-direction: column;
    width: 32rem;
    border: .1rem #c0c0c0 solid;
    border-radius: .5rem;
    list-style-type: none;
    padding: 1rem;

} */

/* .form-items li{
    display: flex;
    flex-direction:column;
    margin-bottom: 1rem;
    margin-top: 1rem;
   
} */

/* .login{ */
    /* height: calc(100vh - 130px);  */
    /* display: flex;
    align-items: center;
    justify-content: center; */

/* } */
.wrapper{
    /* width:50%; */
    /* height: 70%; */
    -webkit-box-shadow: 0px 5px 33px -21px rgba(66 , 68 , 68);
    -moz-box-shadow: 0px 5px 33px -21px rgba(66 , 68 , 68);
    box-shadow: 0px 5px 33px -21px rgba(66 , 68 , 68);
/* display: flex; */
align-items: center;
border-radius: 20px;
padding: 30px;
position: relative;
top:-100px;
}
/* 
.left , .right{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center{
    height: 100;
      display: flex;
align-items: center;
justify-content: center; */
/* position: relative; */

/* } */

/* .or{
    border: 2px solid lightgray;
    border-radius: 50px;
    padding: 10px;
    color: gray;
    background-color: white;
    font-weight: bold;
} */

/* .line{
    width: 0.5px;
    height: 55%;
    background-color: lightgray;
    position: absolute;
    bottom: 0 ;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
} */

/* .loginButton{
    width: 150px;
    padding: 15px 25px;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 20px;

}
.google{
    background-color: #df4930;
}
.facebook{
    background-color: #507cc0;
}
.github{
    background-color: black;
}
.sms{
    background-color: lightgreen;
}

.icon{
    width: 20px;
    height: 20px;
    margin-right:10px ;
} */

/* input{
    width: 200px;
    padding: 15px 20px;
    margin-bottom:20px ;
} */

/* .submit{
    width: 200px;
    background-color: rgba(128,0,128,.671) ;
    color: white;
    font-weight: bold;
    border: none;
    padding: 15px 20px;
    border-radius: 5px;
    margin: .1rem;
    

}
.submit :hover{
    background-color: #df4930;
}

.loginTitle{
    
    top: 170px;
    color: lightgray;
}

 */
