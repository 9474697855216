/* body, html {
  width: 100%;
  height: 100%;
  background-color: #f8f4d5;
} */
/* .containerColoredSpiner {
  width: 100%;
  height: 100%;
  background-color: #f8f4d5;
  
} */

.containerColoredSpiner {
  /* width: 600px;
  height: 600px; */
  position: fixed;
  top: 50%;
  right: 50%;
  /* bottom: 0; */
  /* left: 0; */
  background-color: rgba(90, 78, 62, 0.364);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transform: translate(-50%, -50%);
  margin: auto;
  filter: url('#goo');
  animation: rotate-move 2.5s ease-in-out infinite;
}
.spinnerBackground{
  background-color: rgba(84, 67, 42, 0.341);
  z-index: 9998;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dot { 
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.dot-3 {
  background-color: rgb(89, 62, 36);
  animation: dot-3-move 2.5s ease infinite, index 7.5s ease infinite;
}

.dot-2 {
  background-color: rgb(180, 180, 129);
  animation: dot-2-move 2.5s ease infinite, index 7.5s -4s ease infinite;
}

.dot-1 {
  background-color: rgb(132, 94, 63);
  animation: dot-1-move 2.5s ease infinite, index 7.5s -2s ease infinite;
}

@keyframes dot-3-move {
  20% {transform: scale(1)}
  45% {transform: translateY(-18px) scale(.45)}
  60% {transform: translateY(-90px) scale(.45)}
  80% {transform: translateY(-90px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
}

@keyframes dot-2-move {
  20% {transform: scale(1)}
  45% {transform: translate(-16px, 12px) scale(.45)}
  60% {transform: translate(-80px, 60px) scale(.45)}
  80% {transform: translate(-80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
}

@keyframes dot-1-move {
  20% {transform: scale(1)}
  45% {transform: translate(16px, 12px) scale(.45)}
  60% {transform: translate(80px, 60px) scale(.45)}
  80% {transform: translate(80px, 60px) scale(.45)}
  100% {transform: translateY(0px) scale(1)}
}

@keyframes rotate-move {
  55% {transform: translate(-50%, -50%) rotate(0deg)}
  80% {transform: translate(-50%, -50%) rotate(360deg)}
  100% {transform: translate(-50%, -50%) rotate(360deg)}
}

@keyframes index {
  0%, 100% {z-index: 3}
  33.3% {z-index: 2}
  66.6% {z-index: 1}
}