.chart{
    margin: 20px;
    border-radius: 10px;
    padding: 20px;
    -webkit-box-shadow: -9px 12px 32px 10px rgba(0,0,0,0.3); 
    box-shadow: -9px 12px 32px 10px rgba(0,0,0,0.3);

}

.chartTitle{
color: red !important;
margin: 10px;
}

.user-table {
    border-collapse: collapse;
    width: 100%;
  }

  .user-table th, .user-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  .user-table th {
    background-color: #f2f2f2;
  }

  .user-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }