
/* .alert{
    padding:1rem;
    border: .1rem solid transparent;
    border-radius: .5rem;
}

.alert-info{
    margin: 1rem;
    color: #2020a0;
    background-color: #e0e0ff;
}
.alert-danger{
    margin: 1rem;
    color: #a02020;
    background-color: #ffe0e0e0;
} */
.message-box{
    
    width: 40rem;
}
.message-container{
    width: 100%;
    height: 100%;
}
