.widgetLg{
    flex: 2;
    -webkit-box-shadow: -9px 12px 32px 10px rgba(0,0,0,0.3); 
    box-shadow: -9px 12px 32px 10px rgba(0,0,0,0.3);
padding: 20px;
margin: 20px;
border-radius: 10px;
}

.widgetLgTitle{
    font-size: 22px;
    font-weight: 600;
}

.wedgitLgTable{
    width: 100%;
    border-spacing: 20px;

}
.widgetLgTh{
    text-align: left;
}
.widgetLgUser{
    display: flex;
    align-items: center;
    font-weight: 600;
    margin: 10px 0px;
}

.widgetLgImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.widgetLgDate , .widgetLgAmount{
font-weight: 300;
}

.widgetLgButton{
    /* width: 80px; */
    padding: 5px 8px;
    border: none;
    border-radius: 10px;
}

.widgetLgButton.Approved ,.widgetLgButton.COMPLETED{
    background-color:#e6fae5;
    color: #34761c;
}
.widgetLgButton.Declined,.widgetLgButton.DENIED{
    background-color:#ffdbb8;
    color: #ac2e0b;
}
.widgetLgButton.Pending ,.widgetLgButton.PENDING{
    background-color:#fffec7;
    color: #e7c12a;
}

