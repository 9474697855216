/* SeatingMap.css */
.seating-map {
  display: grid;
  grid-template-columns: repeat(10, 40px); /* Adjust the number of columns as needed */
  grid-gap: 2px; /* Adjust the gap between seats */
  justify-content: center;
}
  
  .seat {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    border-radius: 45px;
    margin: 2px; /* Add some margin for spacing */
    font-size:small
  }
  .row-divider {
    height: 10px; /* Adjust the height as needed */
    width: 100%;
    border-top: 2px solid #ccc;
    margin: 5px 0;
  }
  
  .available { background-color: #aaffaa; }
  .selected { background-color: #ffc743; }
  .booked { background-color: #f82626; }
  