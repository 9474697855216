
.multiLevel  ,.subItem ul, .sidebarItems ul , .sidebar input[type="checkbox"] , .sidebarMenu input[type="checkbox"]{
    display: none;  
   
}
.sidebarItems:first-child ul{ 
    display: flex;
    }
    
/* .nav > #label {display: block;} */
.sidebarItems  input:checked ~ ul{
display: flex;
}
.tab-content  input:checked ~ .tab-pane{
    opacity: 1 !important;
    display: block !important;
    }
/* .sidebar  .sidebarWrapper ,.sidebar  label h3{
    display: none;
 } */

.sidebar-dash input:checked ~ .sidebar ,.sidebar-dash input:checked ~ label h3{
    display: none;
 }
 .sidebar-dash input[type="checkbox"]{
    display: none;
 }
 .sidebar-dash .collapsing{
  /* transition: all .7s linear !important;  */
  transition: all .5s ease-in-out !important;
 }
 /* .sidebar{
    width: 29rem;
 } */
 #menu:checked ~ .sidebar{
    width: 3rem;
    
    
 }


 
 .sidbarHead{
    margin: 0.1rem 0px 0px 1.5rem;
    font-weight: 600;
 }
 .profile-icon{
   
  color: #8a892f;
  font-weight: bold;
  font-size: 2rem;
  
 }


.sidebarItems .arrowForwardIcon{   
width: 12px;
height:12px ;
vertical-align: middle;
}

.sidebarItems  .arrowForwardIcon{
    /* transform: rotate(0deg); */
    transition: 0.3s ease-in-out;
}
 .sidebarItems input:checked ~ label>.arrowForwardIcon{
transform: rotate(-90deg);
/* transition: 0.1s; */
}

label:hover{
    cursor: pointer;
}

#navIcon{
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 25px;
    line-height: 25px;
    margin-left: 0px;
    padding: 0.1rem;
}

.sidebarItems ul{
    padding: 0 0.25em;
    transition: 0.3s ease-in-out;
}
.nav li a{
    line-height: 50px;
    margin: 0px;
    padding: 0 2em;
    color: #cfd8dc;
    font-weight: 600;    
}

.sidebar{  
   display:inline-block;
   background-color: #473c31;
   color: rgb(61, 47, 10);
   position: sticky;
   /* top: 350px; */
   padding-top: 0.5rem;
   margin-top: 0rem;
   height: 100vmax;
   
   
    }
  
  
    @media all and (min-width: 768px) {
        .sidebarWrapper
    {
        padding: 10px;
        width: 15rem;
        /* background-color: 	#d6d0c8; */   
    }
    }
    @media all and (max-width: 767px) {
        .sidebarWrapper
    {
        padding: 10px;
     
        /* background-color: 	#d6d0c8; */   

    }
    }



.sidebarTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
    color: rgb(98, 95, 89); 
    font-weight: bold;
    margin:10px 0px;
    padding: 5px 10px ;
    
}
    .sidebarMenu{
        margin-bottom: 10px;
    }

    .sidebarList{
        display: none;
        flex-direction: column;
        justify-content: center;
        padding: 0px 5px;
        margin-left: 1em;
    }

    .sidebarListItem{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        border-radius: 10px;
        margin-bottom: 5px; 
        color:#ab9b7d;;
    }

    .sidebarListItem.active {
        background-color: #91907b;
        color:#f3e5a2; 
        border-radius: 5px;
    }
    .sidebarListItem2.active {
        background-color: #b6a68c;
        color:#fef3cb; 
        border-radius: 5px;
    }
   

    .sidebarTitle:hover,.sidebarListItem:hover {
        background-color: #e3e0b4de;
        color:#575340; 
        border-radius: 5px;
    }
    .sidebarIcon{
        margin-right: 5px;
        font-size: 20px !important;
    }
 
    .subItem > ul li{
         margin-left: 30px;
         padding: 5px;
         font-weight: 600;      
           }
