.landingPage{
    direction: rtl;
    max-width: 100vw;
    height: 105vh ;
    padding: 50px 25px 200px ;
    box-sizing: border-box;
    background-color: #7D604E;
     background-color: #c89f8334;
    background-image: url('../../../../public/img/landing/Metamarce-9.png');
    background-size: 100vw;
 
    background-attachment: fixed;
    background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

}

@media (max-width: 768px) {
    .landingPage{
        background-image: url('../../../../public/img/landing/Metamarce-mobile.jpg');
    }
  }
.mainSelectBox{
    width: 100%;
   
}
.landing_image{
    width: 100%;
}

    .accordion-button-landingpage::after{
        margin-left: 0;
        margin-right: auto !important;
      
    }
    .landing_icon{
        font-size: 25px;
    }
    .landing-explore{
        color: bisque;
        /* font-size: 40px; */
        /* font-family:Bradley Hand, cursive;  */
          font-size: 30px;
        font-family: "Lalezar-Regular";
        background-color: transparent;
        border: none;
        position: relative;
    }
    .landing-explore:hover{
        color: #cdad86;
        font-size: 36px;
        transition: all .3s ease-in-out;
    }
   
 
    .landingPage .card-background7:hover,.landingPage .card-background0:hover{
        background-color: rgb(255, 242, 210) !important;
        color: rgb(128, 108, 85);
    }

    .lets-go{
        background-color: #907665;
        /* border-radius: .5rem; */
        
    }