:root {
  --brown: #7b635ae4;
  --white: #faf0e6;
  --golbehi: #c2906f97;
  --pink: #f8e3d5;
  --darkBrown: rgb(84, 58, 50);
  --brown2: #74563c;
  --brownPink: #e5d9cd;
  --brownOrange: #b18a61;
  --beige: #eeeae1;
  --brownGray: #7e685b;
  --warmGrayBeige: rgb(233, 114, 29);
  --khaki: #c3b091;
}
.form-control:focus {
  /* border-color: transparent; */
  /* border-color: var(--brownGray); */
  color: var(--brownGray);
}
.checkout-col .form-control:focus {
  /* border-color: transparent; */
  border-color: var(--brownGray);
  color: var(--brownGray);
  /* background-color: #007b5e; */
}
.listing-all-map {
  /* margin: 2rem; */
  padding: 0.5rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.372);
  text-align: center;
  height: 500px;
  margin-left: 20px;
  margin-right: 20px;
  /* margin-left: 50px;
  margin-right: 50px; */
  overflow: hidden;
}
.listing-item-pic-tag {
  font-size: 14px;
  color: #ffffff;
  background: #979124;
  font-weight: 600;
  display: inline-block;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  position: absolute;
  left: 15px;
  top: 15px;
  
}
.listing-item-pic-tag:hover {
  background: #77743ea6;
  transition: 0.3s ease-in-out  all;
  cursor: pointer;
}
.user-sug-tag {
  font-size: 14px;
  color: #fdf7ec;
  background: #634a22a6;
  font-weight: 600;
  /* display: inline-block; */
  padding: 20px;
  /* border-radius: 5px; */
  position: absolute;
  /* left: 15px; */
  bottom: 0px;
  width: 100%;
  /* margin-left: 5px;
  margin-right: 5px; */
}

.user-sug-tag:hover {
  color: #594209;
  background: #cbb77abb;
  transition: all 0.5s ease-in-out ;
  cursor: pointer;
}



.button-filter {
  background-color: transparent;
  border-color: var(--darkBrown);
  color: var(--darkBrown);
  padding: 5px 15px;
  margin: 1rem;
  text-align: center;
  justify-content: space-between;
  text-decoration: none;
  display: flex;
  margin: 5px 10px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 14px;
  position: relative;
}
.box4 {
  border: none;
  font-size: 12px;
  border-radius: 0px;
  box-shadow: none;
  border-left: 1px solid #857b7490;
  border-right: 1px solid #857b7490;
  font-weight: normal;
  background: transparent;
  transition: 0.3s ease-out;
  line-height: 30px;
  color: #857b7490;
}
.blog .box4 {
  line-height: 20px;
  /* vertical-align: middle;
  text-align: center; */
  display: flex;
  align-items: center;
  border: none;
}
.box5 {
  background-color: transparent;
  border-color: #857b7490;
  color: var(--darkBrown);
  padding: 2px 15px;
  margin: 1rem;
  text-align: center;
  /* justify-content: space-between; */
  text-decoration: none;
  /* display: flex; */
  margin: 5px 10px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 14px;
}
.input-pagination {
  background-color: transparent;
  border: none;
  width: 20px;
  text-align: center;
  cursor: pointer;
}
.map-card {
  color: var(--brown2);
  /* font-size: 14px; */
  transition: 0.3s ease-in-out all;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 15px;
}
.map-card:hover {
  color: rgb(92, 81, 4);
  /* text-decoration: underline; */
  background-color: #d3cbb0f6;
}

.map-card-active {
  color: var(--darkBrown);
  /* text-decoration: underline; */
  background-color: #b7ac7df3;
  border-radius: 15px;
}

.direction-pagination {
  font-size: 30px;
  /* padding-top: 20px; */
  vertical-align: middle;
  color: #857b7490;
  /* line-height: 0px; */
  position: relative;
  top: 3px;
}
.direction-pagination:hover {
  color: var(--darkBrown);
  transition: 0.5s ease-in-out all;
  cursor: pointer;
}
.direction-pagination:disabled {
  color: #ff660090;
  /* transition: .5s ease-in-out all;  */
  /* cursor: pointer; */
}
.pagination-from {
  color: #857b7490;
}
.button-filter-tag {
  background-color: var(--khaki);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  color: var(--darkbrown);
  border-color: transparent;
}
.button-filter-tag2 {
  background-color: #c3b0919f;
}
.button-filter-tag3 {
  background-color: #beb29bac;
  border-radius: 3px;
}
.button-filter-tag4 {
  background-color: #bcae942b;
  border-radius: 3px;
  padding-bottom: 0;
  padding-top: 0;
}
.button-filter-tag5 {
  background-color: #edcd6fe2;

  border-radius: 3px;
  padding-bottom: 0;
  padding-top: 0;
}
.button-filter-tag6 {
  background-color: #aeae75c2;
  border-radius: 3px;
  padding-bottom: 0;
  padding-top: 0;
}
.button-filter-tag7 {
  background-color: #d0c091;
  border-radius: 3px;
  padding-bottom: 0;
  padding-top: 0;
}
.button-filter:hover {
  background-color: var(--brownOrange);
  border-color: var(--brownOrange);
  text-decoration: none;
  color: rgb(247, 243, 243);
  transition: all 500ms linear;
}
.button-filter:active {
  background-color: #505b3f;
  text-decoration: none;
  color: rgb(247, 243, 243);
  transition: 2s;
}
.button-filter:focus {
  background-color: var(--brown2);
  border-color: #505b3f;
  box-shadow: 0 0 0 0.15rem rgba(131, 110, 45, 0.5);
  text-decoration: none;
  color: rgb(247, 243, 243);
  transition: 1s;
}
/* button:focus {
	background-color: #545b40;
	text-decoration: none;
	color: rgb(247, 243, 243);
	transition: 1s;
  } */
button:active .icon-filter {
  display: inline-flex;
  color: #f4ecec95;
  transform: rotatez(180deg);
  transition: all 0.3s;
}
.btn-outline-primary:focus .icon-filter {
  display: inline-flex;
  color: #d10c0c95;
  transform: rotatez(180deg);
  transition: all 0.3s;
}

.btn-check:focus + .btn-primary {
  color: #fff;
  background-color: #979745;
  border-color: #c4dc78;
  box-shadow: 0 0 0 0.15rem rgba(156, 161, 98, 0.5);
}

.space {
  margin-left: 2rem;
}

.checkbox-form {
  background: #f9f6f4;
  box-shadow: 1px 3px 8px 0px #929292;
  color: #3e2820;
  margin-top: 50px;
  cursor: pointer;
  padding: 1.5rem;
  min-width: 200px;
  border-radius: 12px;
  transform: translate3d(0, 50px, 0) !important;
  font-size: 12px;
}

.checkbox-form-morefilters {
  min-width: 500px;
}

.learn {
  text-decoration: none;
  color: #fff;
}

.add-list {
  height: 45px;
  line-height: 27px;
  background-color: #d32f2f;
  color: #fff !important;
}

.modal-dialog-filter {
  max-width: 300px;

  /* bottom: 0; */
  /* left: 0; */
  z-index: 1040;
  overflow: auto;
  overflow-y: auto;
}

.modal-filter {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* **************
	Cards
************** */

.image-card {
  width: 100%;
  /* height: 100%;
  height: 210px; */
  object-fit: cover;
  border-radius: 3px;
}
.gm-style-iw-d {
  overflow: hidden !important;
}
.gm-style-iw {
  padding: 0 !important;
}
.map-bus-card {
  width: 180px;
  height: auto;
  z-index: 40;
  margin: 0;
  padding: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
.map-bus-card .card-body {
  padding: 0 !important;
}
.map-card-rating .css-ryrseu-MuiRating-root {
  font-size: 1rem !important;
}
.map-bus-card .image-card {
  width: 100%;
  height: 100%;
}
.map-bus-card .card .card-logo {
  position: absolute;
  bottom: -20px;
  left: 5px;
  border-radius: 50%;
  z-index: 50;
  width: 50px;
  height: 50px;
}
.map-bus-card .group-list-item2 {
  padding-left: 0;
  direction: ltr;
}
.map-bus-card .card {
  font-size: 10px !important;
  font-weight: 300 !important;
}
.map-bus-card .card-titel1 {
  font-family: vazir;
  font-size: 14px !important;
  color: var(--brown) !important;
}
@media screen and (max-width: 770px) {
  .image-card {
    height: 280px;
    /* width: 75%; */
  }
}

@media screen and (max-width: 991px) {
  .btn-bus-reg {
    /* width: 70%; */
    text-align: center !important;
    margin-right: 10px;
  }
}

.card .card-logo {
  position: absolute;
  bottom: -35px;
  left: 5px;
  border-radius: 50%;
  z-index: 50;
  width: 70px;
  height: 70px;
  transition-duration: 1000ms;
}
.card-logo2 {
  right: 5px;
}
.card-logo3 {
 /* display: none; */
  bottom: 0px !important;
  left: 0px !important;
  border-radius: 3px !important;
  /* width: 45% !important; */
  height:22px !important;
  background-color:#78540c82;
  color:#fee29bec;
  /* font-style: italic; */
  /* font-weight: lighter !important; */
  font-family: cursive;
  width: fit-content !important;
}

.card-img-pack:hover ~ .card-logo3{
  display: flex!important;
}
.card .card-logo:hover , .media .card-img-categories:hover{
  box-shadow: 1px 3px 8px 0px #929292;
  filter: saturate(70%);
  transform: scale(1.1);
}
.card-img-pack {
  position: relative;
  /* overflow: hidden; */
}
.business-card-img-wrap {
  position: relative;
  overflow: hidden;
}
.business-card-img {
  position: relative;
  transition-duration: 1000ms;
}
.business-card-img:hover {
  transform: scale(1.2);
}

/* .card-img-pack img{
  position: relative;
 transition-duration: 1000ms;
}
.card-img-pack:hover img{
  transform: scale(1.2);
 
  
} */
.card-logo-certified {
  bottom: 465px;
  left: 230px;
  position: absolute;
  z-index: 5;
  width: 45px;
  height: 45px;
}

.icon-stack2 {
  bottom: -5px;
  left: -175px;
  /* position:absolute; */
  z-index: 5;
  text-align: center;
  color: rgb(57, 75, 21);
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  /* font-size: 2em; */
  vertical-align: middle;
  border: 1.5px solid greenyellow;
  box-shadow: 0 0 0 0.25rem rgba(219, 230, 98, 0.5);
  background-color: rgb(73, 104, 27);
  border-radius: 50%;
  color: white;
  text-align: center;
}

/* .card-title {
    margin-bottom: 0.5rem;
    margin-top: 10rem;
  } */
.card-titel2 {
  color: rgb(120, 105, 20);
  font-weight: 1200 !important;
}
.card-titel1 {
  color: rgb(243, 242, 236);
  font-weight: 1200 !important;
}
.card-titel3 {
  font-size: 14px;
  text-align: center;
  color: rgb(251, 246, 229);
}
.card-titel4 {
  font-size: 18px;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 15px #f0f805;
  background: rgb(215, 205, 187);
  background: linear-gradient(
    90deg,
    rgba(215, 205, 187, 0.1) 10%,
    rgba(181, 166, 142, 0.7) 50%,
    rgba(157, 142, 117, 0.1) 90%
  );
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.CardSlide img {
  height: 180px;
}

.card1 a {
  color: rgb(255, 255, 255);
}
.card1:hover a {
  color: rgb(228, 231, 170);
}
.card-text1 {
  color: var(--darkBrown);
}

.card1 {
  /* height: 170px; */
  overflow: hidden;
  transition: all 0.5s ease;
  border-radius: 20px;
}

.card1:hover {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.card1 img {
  border-radius: 20px !important;
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
}
.card1:hover img {
  transform: scale(1.05);
}

.card1 .card-img-overlay {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: rgb(50, 36, 1);
  background: linear-gradient(
    0deg,
    rgba(50, 36, 1, 0.7) 15%,
    rgba(84, 75, 41, 0.2) 35%,
    rgba(255, 254, 251, 0) 80%
  );
  transition: all 0.5s ease;
  border-radius: 20px !important;
  overflow: hidden;
  padding: 5px;
}
.card1 .card-img-overlay:hover {
  background: rgb(5, 4, 0);
  background: linear-gradient(
    0deg,
    rgba(5, 4, 0, 0.75) 30%,
    rgba(37, 33, 18, 0.5) 60%,
    rgba(65, 53, 18, 0.3) 90%,
    rgba(120, 96, 22, 0.2) 100%
  );
  filter: grayscale(0.7);
}

.card-img-overlay-unclaimed {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: rgb(5, 4, 0);
  background: linear-gradient(
    0deg,
    rgba(5, 4, 0, 0.6) 25%,
    rgba(37, 33, 18, 0.4) 50%,
    rgba(37, 33, 18, 0.3) 75%,
    rgba(65, 53, 18, 0.2) 90%,
    rgba(120, 96, 22, 0.1) 100%
  );
  transition: all 0.5s ease;

  overflow: hidden;
}
.overlay-text {
  opacity: 0;
  transition: all 0.5s ease;
}

.card-img-overlay-unclaimed:hover {
  background: rgb(5, 4, 0);
  background: linear-gradient(
    0deg,
    rgba(5, 4, 0, 0.75) 30%,
    rgba(37, 33, 18, 0.5) 60%,
    rgba(65, 53, 18, 0.3) 90%,
    rgba(120, 96, 22, 0.2) 100%
  );
}
.card-img-overlay-unclaimed:hover .overlay-text {
  opacity: 1;
}
.portfolio-item:hover .overlay-text {
  opacity: 1;
}

.card-img-overlay-unclaimed:hover p {
  color: var(--beige) !important;
}
/* .unclaimed-img {
  filter: saturate(0.4);
  filter: grayscale(.6);
} */
.background-band-1 {
  background-color: var(--brown);
}
.icon-stack {
  position: relative;
  display: inline-block;
  width: 3em;
  height:3em;
  line-height: 3em;
  vertical-align: middle;
  /* margin-right: 2rem; */
  border: 1.5px solid var(--brown);
  border-radius: 50%;
  color: var(--brown);
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  cursor: pointer;
}
.icon-stack3 {
  margin-right: auto;
}
.blog .entry .icon-stack3 ul li {
  padding-left: 1px !important;
}

.icon-stack:hover {
  color: rgb(255, 255, 255);
  background-color: var(--brown);
}

.icon-stack-1x {
  /* position: absolute; */
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0 !important;
  font-size: 1.5em !important;
  z-index: 50;
}
.card1 .btn-primary:disabled,
.btn-primary.disabled {
  color: #85817c;
  background: none;
  border-color: #85817c;
}
.card-no-stars {
  margin-top: 3.2rem;
  margin-bottom: 0rem;
}
.icon-stack-2x {
  font-size: 0.8em !important;
  /* font-weight: bold; */

  left: 1px;
  /* width: 1.5em;
    height: 1.5em; */
  text-align: center;
  position: inherit;
}

.icon-stack:hover {
  color: rgb(255, 255, 255);
  background-color: var(--brown);
}

.btn-card {
  background-color: transparent;
  border-color: var(--brown);
  border-radius: 25px;
  color: var(--brown);
  font-size: 80%;
  border: 1.5px solid;
}
.btn-card:hover {
  background-color: var(--brown);
  border-color: var(--brown);
}

.sidebar-listing {
  background-color: #e3e1e1e9;
  /* border-top-left-radius: 30px;
	border-top-right-radius: 30px; */
  border-radius: 5px;
}

.sidebar-title-listing {
  border-radius: 20px;
  color: #5e4f4f;
  background-color: #f0ece8;
  padding: 10px 35px 10px 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* text-shadow: 4px 4px 5px #D2B48C */
}

.main-title-listing {
  border-radius: 20px;
  color: #f9f5f5;
  background-color: #9d9791d6;
  padding: 10px 75px 10px 10px;
  margin-top: 5rem;
  margin-bottom: 2rem;
  /* text-shadow: 4px 4px 5px #D2B48C */
}


.banner-listing-2 {
 
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.accordion-button2 {
  color: #e8dad3;
  background-color: rgba(97, 75, 61, 0.721);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button2b{
  background-color: rgba(118, 90, 71, 0.721) !important; 
}
.accordion-button2c{
  background-color: rgba(133, 100, 78, 0.721) !important; 
}
.accordion-button2d{
  background-color: rgba(147, 110, 84, 0.721) !important; 
}
.accordion-button2e{
  background-color: rgba(155, 114, 86, 0.721) !important; 
}
.accordion-button2f{
  background-color: rgba(255, 148, 77, 0.03) !important; 
  color: rgb(166, 150, 145) !important;
  font-weight: lighter !important;
}

.accordion-button2:not(.collapsed) , .accordion-button:not(.collapsed){
  color: rgba(97, 75, 61, 0.954) !important;
  background-color: #bfb7b2 !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125) !important;
}
.accordion-button2f:not(.collapsed){
  background-color: rgba(164, 143, 129, 0.3) !important; 
  color: rgb(110, 69, 55);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125) !important;
  font-weight: bolder !important;
}
.accordion-button3 {
  color: #e8dad3;
  background-color: rgba(97, 75, 61, 0.721);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button3:not(.collapsed) {
  color: rgba(97, 75, 61, 0.954);
  background-color: #b8aea8;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button3 {
  color: #392a23;
  background-color: rgba(123, 74, 46, 0.16);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button3:not(.collapsed) {
  color: rgb(97, 75, 61);
  background-color: #8e80789f;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-item2 {
  background-color: transparent;
}
.img-banner-2 img {
  max-width: 100%;
  max-height: 100%;
}
/* .faq-img{
  width: 70%;
    height: 70%;
} */
.img-banner-background {
  background-color: rgba(99, 98, 55, 0.26);

  border-radius: 3px;
}
.img-banner-text {
  background-color: rgb(212, 204, 192);
}
.img-banner-text-2 {
  background-color: var(--beige);
}
.img-banner-text-3 {
  background-color: var(--khaki);
}
.img-banner-text-4 {
  background-color: #fdf1f0;
}
.img-banner-text-5 {
  background-color: rgba(131, 96, 59, 0.094);
}
.img-banner-text-6 {
  background-color: rgba(168, 153, 112, 0.26);
}
.img-banner-text-7 {
  background-color: rgba(137, 125, 91, 0.26);
}
.img-banner-text-8 {
  background-color: rgba(209, 189, 158, 0.649);
}
.img-banner-text-9 {
  background:linear-gradient(
    90deg,
    rgba(205, 201, 175, 1) 0%,
    rgb(180, 168, 117, .7) 34%,
    rgb(134, 123, 81 , .7) 76%,
    rgb(81, 75, 50, .9) 100%
    )
}
@media screen and (max-width: 767px) {
.img-banner-text-9 {
  background:linear-gradient(
    0deg,
    rgba(205, 201, 175, 1) 0%,
    rgb(180, 168, 117, .7) 34%,
    rgb(134, 123, 81 , .7) 76%,
    rgb(81, 75, 50, .9) 100%
    )
}}
.img-banner-text-10 {
  background:linear-gradient(
    90deg,
    rgb(233, 206, 168) 0%,
    rgba(166, 131, 83, 0.7) 34%,
    rgba(125, 95, 49, 0.7) 70%,
    rgba(63, 38, 7, 0.9) 100%
    )
}
@media screen and (max-width: 767px) {
  .img-banner-text-10 {
    background:linear-gradient(
      0deg,
      rgb(233, 206, 168) 0%,
      rgba(166, 131, 83, 0.7) 34%,
      rgba(125, 95, 49, 0.7) 70%,
      rgba(63, 38, 7, 0.9) 100%
      )
  }
 }

.banner-listing {
  margin: 0;
  padding-left: 10rem;
  padding-right: 10rem;
  height: 500px;
  background-color: rgb(205, 190, 170);
}

/* FontAwesome for working BootSnippet :> */

@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
#team {
  background: #eee !important;
}

/* .btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
} */

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}

#team .card {
  border: none;
  background: #ffffff;
}

/* .image-flip:hover .backside,
.image-flip.hover .backside {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: .25rem;
} */

.image-flip2 {
  position: relative;
}

/* .image-flip:hover .frontside,
.image-flip:hover .frontside {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
} */

.mainflip {
  -webkit-transition: 2s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 2s;
  -moz-transition: 2s;
  /* -moz-transform: perspective(1000px); */
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 2s;
  transform-style: preserve-3d;
  position: relative;
}

.frontside {
  position: relative;
  /* -webkit-transform: rotateY(0deg); */
  /* -ms-transform: rotateY(0deg); */
  z-index: 2;
}

.backside {
  position: absolute;
  top: 0;
  left: 0;
  background: white !important;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 2s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 2s;
  -moz-transform-style: preserve-3d;
  -o-transition: 2s;
  -o-transform-style: preserve-3d;
  -ms-transition: 2s;
  -ms-transform-style: preserve-3d;
  transition: 2s;
  transform-style: preserve-3d;
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside .card,
.backside .card {
  min-height: 312px;
}
.backside .card {
  background-color: white;
}

.frontside .card .card-title,
.backside .card .card-title {
  color: var(--brown2) !important;
}

/* 
.frontside .card .card-body img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
} */

.frontside2 {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.backside2 {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: 0.25rem;
}

.fixed-bg-listing {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to top, #3e2a18, rgba(110, 101, 90, 0.1)),
    /* linear-gradient(to top, #4c352111, rgba(42, 38, 32, 0.24)), */
      url("../public/img/homePage/h2-background-4-2.jpg");
  /* background-image: url('http://metamarce.com/img/thumb-1920-430779.jpg'); */
  /* min-height: 500px; */
  /* background-attachment:fixed; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: soft-light;
  width: 100%;
  height: 100%;
  opacity: 1;
  /* filter: grayscale(10%); */
  filter: sepia(1) contrast(1.25) grayscale(0.1);
}
.fixed-bg-jobs {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to top, #3e2a18, rgba(110, 101, 90, 0.1)),
    /* linear-gradient(to top, #4c352111, rgba(42, 38, 32, 0.24)), */
      url("../public/img/Jobs/pattern.svg"); 

  /* min-height: 500px; */
  /* background-attachment:fixed; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: soft-light;
  width: 100%;
  height: 100%;
  filter: saturate(0.6) contrast(1.5);
}
.fixed-bg-media {
  background: rgb(205, 201, 175);
  background: linear-gradient(
    90deg,
    rgb(228, 225, 204) 0%,
    rgba(187, 182, 123, 1) 44%,
    rgba(145, 143, 83, 1) 76%,
    rgba(62, 69, 43, 1) 100%
  );
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-image: */
  /* linear-gradient(to left, #656340, rgba(218, 204, 112, 0.1)); */
  /* linear-gradient(to top, #4c352111, rgba(42, 38, 32, 0.24)), */
  /* url("http://metamarce.com/img/Jobs/svg.png"); */

  /* min-height: 500px; */
  /* background-attachment:fixed; */

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: soft-light;
  width: 100%;
  height: 100%;
  filter: grayscale(0.3);
}
.bg-contact-us{
  background: rgb(205, 201, 175);
  background: linear-gradient(
    0deg,
    #eae6de 0,
    #e6e0d2 15%,
    #e5ddc9 35%,
    #d7c9b8 55%,
    #b09171 79%,
    #60412b 100%
  );
}
.header-listing-overlay {
  /* background-color: rgba(65, 43, 31, 0.16); */
  /* Black background with opacity */
  margin: auto;
  /* padding: 3rem; */
  /* height: 60vh; */
  /* border-radius: 5px; */
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  /* min-height: 500px; */
}

/*---------------------
  Hero
-----------------------*/
.hero__search__form {
  margin-bottom: 30px;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 5px;
}

.hero__search__form form input {
  width: 40%;
  float: left;
  font-size: 15px;
  color: #a8a8a8;
  padding-left: 30px;
  height: 54px;
  border: none;
}

.hero__search__form form input::-webkit-input-placeholder {
  color: #a8a8a8;
}

.hero__search__form form input::-moz-placeholder {
  color: #a8a8a8;
}

.hero__search__form form input:-ms-input-placeholder {
  color: #a8a8a8;
}

.hero__search__form form input::-ms-input-placeholder {
  color: #a8a8a8;
}

.hero__search__form form input::placeholder {
  color: #a8a8a8;
}

.hero__text .section-title h2 {
  color: #ffffff;
}

.hero__text .section-title p {
  color: #a8a8a8;
}

.hero__text .hero__categories__tags {
  text-align: center;
  display: flex;
  justify-content: center;
  justify-content: space-around;
  font-size: 15px;
  color: #ffffff;
  padding: 10px 25px 10px 20px;
  display: block;
  border: 1px solid #ebebeb;
  margin-bottom: 10px;
}

/* .shift-down{
    margin-top: 23.43rem;} */

/*---------------------
  Listing Hero
-----------------------*/
.listing__hero__widget .listing__hero__widget__rating {
  display: inline-block;
  margin-right: 40px;
  position: relative;
  margin-bottom: 15px;
}

.listing__hero__widget .listing__hero__widget__rating:before {
  position: absolute;
  right: -27px;
  top: 11px;
  height: 1px;
  width: 4px;
  background: #ffffff;
  content: "";
}

.listing__hero__widget .listing__hero__widget__rating span {
  font-size: 15px;
  color: #f09d17;
  margin-right: -3px;
  display: inline-block;
}

.listing__hero__widget div {
  font-size: 15px;
  color: #e1dede;
  display: inline-block;
}

/* ********** */
/* *******************STARS************************ */
.overal-stars {
  color: var(--beige);
  font-size: 15px;
  padding-left: 3px;
}

.star-rating {
  direction: rtl;
  display: inline-block;
  padding: 20px;
  cursor: default;
}

.star-rating input[type="radio"] {
  display: none;
}

.star-rating label {
  color: #bbb;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.modal-body input[type="radio"]:checked ~ label {
  color: #7f8c3e;
  font-weight: bold;
}
.modal-body input[type="radio"] ~ label {
  color: #a89f9b;
}
.star-rating label:hover,
label:hover ~ label,
.star-rating input[type="radio"]:checked ~ label {
  color: #f2b600;
}
.star-off {
  color: #85817c77;
}
.star-filter {
  color: #f2b600;
  padding-left: 3px;
  font-size: 18px;
}
.star-filter:hover {
  color: var(--brownOrange);
  transition: 0.3s ease-in-out;
}
/* .card-logo {
  bottom: 0px;
  left: 0px;
  position: absolute;
  z-index: 5;
} */

.listing-cover-page {
  overflow: hidden;
}

.listing-cover-page h2 {
  font-size: 40px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 6px;
}

.listing-cover-page p {
  font-size: 15px;
  color: #e1dede;
}

.listing-cover-page p span {
  color: var(--brownPink);
  margin-right: 5px;
  font-size: 12px;
}

.listing__hero__widget .listing__hero__widget__rating {
  display: inline-block;
  margin-right: 40px;
  position: relative;
  margin-bottom: 15px;
}

.listing__hero__widget .listing__hero__widget__rating:before {
  position: absolute;
  right: -27px;
  top: 11px;
  height: 1px;
  width: 4px;
  background: #ffffff;
  content: "";
}

.listing__hero__widget .listing__hero__widget__rating span {
  font-size: 15px;
  color: #f09d17;
  margin-right: -3px;
  display: inline-block;
}

.listing__hero__widget div {
  font-size: 15px;
  color: #e1dede;
  display: inline-block;
}

.listing__hero__btns .primary-btn {
  font-size: 15px;
}

.listing__hero__btns .primary-btn .share-btn {
  background: rgba(255, 255, 255, 0.2);
  margin-right: 6px;
}

.listing__hero__btns .primary-btn i {
  margin-right: 5px;
}

/* MY listing      ********************** */
.listing-page {
  margin-left: 3rem;
  margin-right: 3rem;
}
.listing-tag {
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 15% 50%, 0 0);
  background-color: #ff4a52;
  padding: 5px 5px 5px 25px;
  text-align: center;
}

.listing-details-information {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;

  /* padding: 1rem; */
  /* margin: 1.5rem; */
  /* margin-right: 3rem; */
}
.listing-details-map {
  /* margin-top: 2rem; */
  /* padding: 1rem; */
  border-radius: 10px;
  background-color: white;
  text-align: center;
}

/* ********Search bar****** */

/*search box css start here*/
.search-sec {
  padding: 2rem;
  /* margin-bottom: 120px; */
  z-index: 100;
}

.search-slt {
  display: block;
  width: 100%;
  /* font-size: 1.1rem; */
  line-height: 1.5;
  /* background-color: rgba(227, 216, 200, 0.797); */
  background-image: none;
  border: none;
  height: calc(3rem + 2px) !important;
  border-radius: 0;
}
.search-slt1 {
  font-size: 1.1rem;
  background-color: rgba(227, 216, 200, 0.797);
  /* color: var(--brown2);  */
}
.search-sec input::placeholder {
  color: rgb(149, 122, 88) !important;
}

.search-slt2 {
  font-size: 0.9rem;
  background-color: rgba(167, 158, 146, 0.797);
}
.search-slt:focus, .search-slt:active , .search-slt:hover  {
  background-color: rgba(73, 68, 60, 0.797);
  color: rgba(227, 216, 200);
}

.wrn-btn {
  width: 100%;
  font-size: 16px;
  font-weight: 400;

  height: calc(3rem + 2px) !important;
  border-radius: 0;
  border: none;
  background-color: #e9d4b0;
  color: #5e534f;
}

.wrn-btn:hover , .wrn-btn:active , .wrn-btn:focus , .wrn-btn:focus-visible {
  border: #deccb0 !important;
  background-color: #5e4f4f !important;
  color: #f0e5d2 !important;}
  

.wrn-btn-rounded {
  border-radius: 5px;
  margin: 0rem;
}
.wrn-btn-rounded:hover {
  transform: scale(1.02);
  transition: 0.5s ease-in-out;
  box-shadow: 0 1rem 1rem #5e4f4f87;
}
@media (min-width: 1200px) {
  .card-categories {
    margin-left: 7rem;
    margin-right: 7rem;
  }
}
@media (max-width: 1199px) {
  .card-categories {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

@media (max-width: 767px) {
  .card-categories {
    margin-left: .5rem;
    margin-right: .5rem;
  }
}

.card-img-categories {
  height: 125px;
}
@media (max-width: 767px) {
  .media .card-categories {
    height: 100px !important;
  }
}
.media .med-img-categories{
height: 100px;
  /* border-radius: 50%; */
  cursor: pointer;
}
@media (max-width: 767px) {
  .media .med-img-categories {
    height: 75px !important;
  }}

.media .med-img-categories:hover{
  /* box-shadow: 1px 3px 8px 0px #929292; */
  filter: saturate(120%);
  transform: scale(1.05);
  transition: all 0.35s ease-in-out;
 
}
.card-title-categories {
  color: var(--darkBrown);
  font-size: 15px;
}
/* .card-background0 {
  background-color: var(--beige);
  border-radius: 0.25rem;

} */

.card-background0 {
  background-color: rgba(222, 184, 135, 0.625);
  border-radius: 0.25rem;
  /* padding: 0 !important; */
}
.card-background1 {
  background-color: #9f6d5e66;
  border-radius: 0.25rem;
}
.card-background2 {
  background-color: var(--pink);
  border-radius: 0.25rem;
}
.card-background3 {
  background-color: #76504266;
  border-radius: 0.25rem;
}
.card-background4 {
  background-color: #9671436d;
  border-radius: 0.25rem;
}
.card-background5 {
  background-color: #c9a77e95;
  border-radius: 0.25rem;
}
.card-background6 {
  background-color: #763e2041;
  border-radius: 0.25rem;
}
.card-background7 {
  background-color: var(--golbehi);
  border-radius: 0.25rem;
}
.card-background8 {
  background-color: rgba(173, 155, 97, 0.625);
  border-radius: 0.25rem;
}
.card-background9 {
  background-color: rgba(191, 163, 126, 0.625);
  border-radius: 0.25rem;
}
.card-background10 {
  background-color: rgba(200, 192, 142, 0.625);
  border-radius: 0.25rem;
}
.card-background11 {
  background-color: rgba(182, 150, 109, 0.625);
  border-radius: 0.25rem;
}
.ads-background0 {
  background-color: #d5bdaf;
}
.ads-background1 {
  background-color: #c5b1a5;
}
.ads-background2 {
  background-color: #e3d5ca;
}
.ads-background3 {
  background-color: #dacec5;
}
.ads-background4 {
  background-color: #f5ebe0;
}
.ads-background5 {
  background-color: #ffe5d9;
}
.ads-background6 {
  background-color: #d6ccc2;
}
.ads-background7 {
  background-color: #d9d9d9;
}
.ads-background8 {
  background-color: #c9b29aea;
}
.ads-background9 {
  background-color: #e7d2bbe9;
}
.ads-background10 {
  background-color: #f4e3cfe9;
}
.ads-background11 {
  background-color: #fef6ed;
}

.divider {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  border-top: 3px solid rgb(17, 16, 16);
  border-radius: 5px;
  background-color: #5e4f4f;
  margin-bottom: 3rem;
}
.card4 img {
  transition: all 0.35s ease-in-out;
}
.card4 {
  position: relative;
  /* padding-bottom: 56.25%; */
}

.card4:hover img {
  transform: scale(1.08);
  box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.35) !important;
  filter: grayscale(20%);
  /* transform: rotatey(180deg); */
}
.card4-5{
  border-radius: 50%;
  padding: 5px;
  height: 80px;
  width: 80px;
  background-color: transparent;

}

.sections-gap {
  margin-top: 5rem;
  margin-left: 3rem;
  margin-right: 3rem;
}
.dark-background {
  margin-top: 3rem;
  background-color: #5b472ead;
}

.divider2 {
  /* text-align: center; */
  border-top: none;
  border-left: none;
  border-right: none;

  border-bottom: 2px solid var(--darkBrown);
  font-size: 22px;
  font-weight: 900;

  color: var(--darkBrown);
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  /* padding: 10px 75px 10px 10px; */
  margin-top: 3rem;
  margin-bottom: 1rem;
}


.divider-light {
  /* text-align: center; */
  border-top: none;
  border-left: none;
  border-right: none;

  border-bottom: 2px solid rgba(235, 234, 234, 0.858);
  font-size: 22px;
  font-weight: 900;

  color: rgba(235, 234, 234, 0.858);
  width: 50%;
  margin-left: auto;
  margin-right: auto;

  margin-top: 2rem;
  margin-bottom: 3rem;
}
@media screen and (max-width: 767px) {
  .divider2 , .divider-light {
    width: 90% !important;
  }
}

.divider-small {
  font-size: 17px;
  color: #594209;
  border-bottom: 2px solid #594209;
  text-align: right;
  width: 90%;
  margin-top: 0rem;
  margin-bottom: 2rem;
  font-style: italic;
}
.divider-small-en{
  text-align: left !important;
  font-size: 18px;
  font-weight: bold;
  
}
.page-en{
  font-size: 16px ;
  font-family: Verdana, Geneva, Tahoma, sans-serif, Arial, Helvetica, sans-serif !important;
  color: var(--brownGray);
 
}

.arrow {
  color: var(--darkBrown);
  width: 50px;
  transform: rotateY(180deg);
}
.img-facilities {
  width: 50px;
}
.img-facilities:hover {
  filter: saturate(190%);
  filter: brightness(150%);
}

.arrow-position2 {
  vertical-align: top;
  position: relative;
  width: 90px;
  height: 120px;
  /* left: 70px; */
}
@media screen and (max-width: 767px) {
 .arrow-position2{
  width: 60px;
  height: 80px;
  }
}

.arrow-position3 {
  /* transform: scale(2, 2); */
  transform: rotatez(80deg);
  position: absolute;
  top: 90px;
  left: 20px;
}
.arrow-box {
  position: relative;
}
.small-card1 {
  background-color: #3a310d30;
}
.small-card2 {
  background-color: #6131063f;
}

.small-card3 {
  background-color: #5e534f30;
}
.small-card4 {
  background-color: #8256463f;
}
.img-card-h {
  object-fit: cover;
}

/* Position the image container (needed to position the left and right arrows) */
.container-modal-image {
  position: relative;
  width: 100%;
}

.container-modal-image img {
  vertical-align: middle;
}

/* Hide the images by default */
.container-modal-image .mySlides {
  display: none;
}

/* Add a pointer when hovering over the thumbnail images */
.container-modal-image .cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.container-modal-image .prev,
.container-modal-image .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "prev button" to the right */
.container-modal-image .prev {
  right: 0;
  border-radius: 3px 0 0 3px;
}
/* Position the "next button" to the right */
.container-modal-image .next {
  left: 0;
  border-radius: 3px 0 0 3px;
}
/* On hover, add a black background color with a little bit see-through */
.container-modal-image .prev:hover,
.container-modal-image .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.container-modal-image .numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  z-index: 100;
}

/* Container for image text */
.container-modal-image .caption-container {
  text-align: center;

  padding: 1rem;
  color: var(--darkBrown);
}

.container-modal-image .row:after {
  content: "";
  display: table;
  clear: both;
}

/* Six columns side by side */
.container-modal-image .column {
  float: left;
  width: 16.66%;
}

/* Add a transparency effect for thumnbail images */
.container-modal-image .demo {
  opacity: 0.5;

  height: 70px;
}

.container-modal-image .active,
.demo:hover {
  opacity: 1;
}
.container-modal-image .img-lg {
  width: 100%;
  height: auto;
  /* object-fit: cover; */
}
@media screen and (max-width: 770px) {
  .image-lg {
    height: 180px;
  }
}

/*Profile Pic Start*/
.picture-container {
  width: 125px;
  position: relative;
  cursor: pointer;
  text-align: start;
}
.picture-container3{
  width: 80px;
  position: relative;
  cursor: pointer;
  text-align:center;
  align-items: center;

}
.picture {
  width: 150px;
  height: 150px;
  background-color: #999999;
  border: 4px solid #cccccc;

  color: #ffffff;
  border-radius: 50%;
  margin: 0px auto;
  overflow: hidden;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.picture1 {
  border-radius: 2px;
  width: 120px;
  height: 120px;
}
.picture2 {
  width: 100px;
  height: 100px;
  background-color: transparent !important;
  border: 4px solid #cccccc;
}
.picture3 {
  width: 60px;
  height: 60px;
  background-color: transparent !important;
  border: 3px solid #cccccc;
}
.picture:hover {
  border-color: #b3a053;
  box-shadow: 0 0 1rem rgba(233, 214, 168, 0.619) !important;
}

.picture input[type="file"] {
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
}

.picture-src {
  width: 100%;
}
img {
  vertical-align: middle;
}
/*Profile Pic End*/

.calendar {
  margin: 0rem;
  background-color: whitesmoke;
  border: 0;
  width: 100%;
  height: 300px;
  scroll-snap-type: mandatory;
}

.comments {
  background-color: rgba(131, 104, 90, 0.283);
}

.bg-comment {
  background-color: #f5f2f012;
}
.bg-reply {
  background-color: #fcf9f81b;
}

.date {
  font-size: 11px;
}

.comment-text {
  font-size: 14px;
  background-color: var(--brownPink);
  padding: 1rem;
  margin-bottom: 0;
  color: var(--darkBrown);
}
.reply-comment {
  padding-right: 3rem;
}
.reply-comment .comment-text {
  font-size: 12.5 px;
}

.fs-12 {
  font-size: 12px;
  padding-bottom: 1rem;
}

.shadow-none {
  box-shadow: none;
}

.name {
  color: var(--darkBrown);
}

.cursor {
  cursor: pointer;
}

.textarea {
  resize: none;
}

.form-titr {
  font-size: 16px;
  font-weight: bolder;
  color: var(--brown);
  /* border-bottom: 1px solid var(--darkBrown); */
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-thickness: 2px;
  margin-top: 2rem;
}
.form-titr2 {
  vertical-align: middle;
  margin-bottom: 0px;

  /* border-bottom: 1px solid var(--darkBrown); */
  /* text-decoration: underline; */
  /* text-underline-offset: 10px; */
  /* text-decoration-thickness: 2px; */
  /* margin-top: 2rem; */
}
.media-footer-icons{
  font-size: 18px;
  color: var(--darkBrown);
 cursor: pointer;
}
.media-footer-icons:hover{
  transform: scale(1.05);
  /* transform: translateY(-2px); */
  color: var(--brownOrange);
}
.colab-icons{
  width: 35px !important;
  filter:saturate(.5);
  cursor: pointer;
}
.colab-icons:hover{
  transition: all 0.5s ease-in-out;
  transform: scale(1.1);
}
/* *********** Filters *************************************/
.accordion-button-business-filter {
  font-size: 16px;
  font-weight: bolder;
  color: var(--brown);
  transition: color 0.5s ease-in-out;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.accordion-button-business-filter:not(.collapsed) {
  color: var(--darkBrown);
  background-color: #d9d3d242;
  box-shadow: none;
}
.accordion-button-business-filter:hover {
  color: #bb8f29bb;
}

.accordion-button-business-filter:not(.collapsed)::after {
  filter: grayscale(100%);
}

.moreLang {
  display: none;
}
#flexLanguages0 ~ .red,
#flexLanguages1 ~ .red,
#flexLanguages2 ~ .red,
#flexLanguages0,
#flexLanguages1,
#flexLanguages2 {
  /* visibility: visible!important; */
  display: inline-block !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
  font-weight: bolder;
  margin-bottom: 1rem;
  vertical-align: middle;
}

#moreLangCheckbox {
  display: none;
}

/* input:checked ~ .moreLang{
  display: inline-block !important;

  color: rgb(231, 222, 220);
  background-color: #007b5e;
  
} */

.form-font {
  font-size: 14px;
  color: var(--darkBrown);
}
/* BusinessPage ************* */
.save-cover-btn {
  background-color: var(--brown2);
  border-color: var(--brown2);
  color: var(--beige);
  transition: all 0.4s ease-in-out;
}
.save-cover-btn:hover {
  background-color: var(--beige) !important;
  border-color: var(--beige) !important;
  color: var(--brown2) !important;
}
.share-cover-btn {
  background-color: var(--brownOrange);
  border-color: var(--brownOrange);
  color: var(--beige);
}

.share-cover-btn:hover {
  background-color: var(--beige) !important;
  border-color: var(--beige) !important;
  color: var(--brownOrange) !important;
}

.share-cover-btn:focus {
  background-color: var(--brownOrange);
  border-color: var(--brownOrange);
  color: var(--beige);
  /* color: #fff; */
  /* background-color: #979745; */
  /* border-color: #c4dc78; */
  box-shadow: 0 0 0 0.15rem rgba(237, 207, 114, 0.5);
}

.business-name-cover {
  font-family: "lalezar-regular";
  color: var(--beige);
 
  font-size: 40px;
}
.uni-name-cover {
  font-family: "lalezar-regular";
color: var(--brownGray);
  font-size: 40px;
}
.list-group-multiselect {
  display: none;
  min-width: 200px;
  height: 350px;
  overflow: auto;
  background: #f9f6f4;
  box-shadow: 1px 3px 8px 0px #929292;
  color: #3e2820;
  cursor: pointer;
  /* padding: 1.5rem; */
  padding: 0;
  border: none;
  border-radius: 12px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  transform: translate3d(0, 50px, 0) !important;
  font-size: 12px;
  transform: translate3d(0, 0, 0);
  left: 0;
  top: 20px;
}
/* .list-group-multiselect .form-check:hover {
  background-color: var(--beige);
  transition: 0.3s ease-in-out;
} */
.dropdown-menu .form-check:hover {
  background-color: var(--beige);
  transition: 0.2s ease-in-out;
}
.BusRegList {
  max-height: 240px;
  overflow: auto;
  font-size: smaller;
  color: var(--brownGray);
  background-color: #dfddda;
  border-radius: 10px;
}
.BusRegList a:hover {
  background-color: rgba(207, 191, 160, 0.502);
  color: rgb(136, 80, 57);
  transition: all 0.2s ease-in-out;
}

.register-curve {
  background-color: rgb(211, 192, 178);
  color: rgb(48, 23, 23);
  width: 100%;
  height: 200px;
}

.claim-box {
  border: 1px solid var(--brownOrange);
  border-radius: 5px;

  padding: 25px;
  margin: 20px;
}

.claim-box2 {
  border-top: 1px solid var(--brownOrange);

  padding: 5px;
}

/* switch-: */
.switch-button {
  background: var(--khaki);
  /* margin-top: -95px; */
  margin-bottom: 45px;
  margin-right: 50px;
  border-radius: 30px;
  overflow: hidden;
  width: 250px;
  height: 40px;
  text-align: center;
  font-size: 12.5px;
  font-weight: bold;
  /* letter-spacing: 1px; */
  color: var(--brownGray);
  position: relative;
  padding-right: 125px;
  position: relative;
}

.switch-button:before {
  content: "کاربر";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 125px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
  padding-right: 2px;
}

.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(123px);
  transition: transform 300ms linear;
  color: var();
}

.switch-button-checkbox + .switch-button-label {
  position: relative;
  height: 40px;
  padding: 10px 0;
  display: block;
  align-items: center;
  user-select: none;
  pointer-events: none;
}

.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: #ffffffa9;
  height: 90%;
  width: 95%;
  position: absolute;
  left: 4px;
  top: 2px;
  border-radius: 30px;
  transform: translateX(0);
  transition: transform 300ms;
}

.switch-button-label-span {
  position: relative;
}
.switch-bg {
  background-color: #f4f2f0;
}
.business-top-curve {
  background-color: #f4f2f0;
  height: 150px;
  overflow: hidden;
  width: "100%";
}
.small-gray-font {
  font-size: 12px;
  color: #7e726e;
}
.med-gray-font {
  font-size: 14px;
  color: #635a56;
}
.bold-dark-font {
  color: var(--brownGray);
  font-weight: bold;
}

.business-register-Img {
  width: 102px;
  height: 63px;
  /* border-radius: 50%; */
  object-fit: cover;
  margin: 10px;
}
.photo-delete {
  color: var(--brownOrange);
  position: absolute;
  right: 0px;
  font-size: 20px;
}
.jaaxi {
  position: relative;
}

.bus-preview-container {
  margin-top: 30rem;
}
.userShowImg-buscard {
  width: 150px !important;
  height: 100px;
  border-radius: 5px;
}
.userShowImg-buscover {
  width: 300px !important;
  height: 100px;
  border-radius: 5px;
}
.userShowImg-busphotos {
  width: 150px !important;
  height: 100px;
  border-radius: 5px;
  background-color: var(--brownOrange);
}
.userShowImg-buslogo {
  width: 100px !important;
  height: 100px;
  border-radius: 50%;
  background-color: var(--brownOrange);
}
.userShowImg-subcat {
  width: 50px !important;
  height: 50px !important;
}
/* _________________ STORE __________ */
.store-select-btn {
  color: var(--brown2);
  font-size: 12px;
  height: 25px;
  width: 50px;
  background-color: var(--beige);
  cursor: pointer !important;
  align-items: center;
  border-color: var(--beige);
}

.form-check-input-payment {
  float: left !important;
  margin-left: -1.5em !important;
}
