:root {
  --pinkOrange: #f8ab94;
  --pinkDark: #e07a80;
  --deepGreen: #4d4f40;
  --pinkOrangeDark: #db7e5c;
  --redDark: #c2503a;
  --greenGray: #87a294;
  --grayGreen: #8f9e9d;
  --beigeYellow: #fff6e5;
  --beigeDark: #e0dbcb;
  --beigeGray: #fcf4e8;
  --lightPink: #ffddda;
  --warmBrown: #8f6234;
  --coldBrown: #968674;
}
.en-font{
  font-size: 110% !important;
}

.bg-deepGreen {
  background-color: #4d4f40 !important;
}
.bg-pinkOrangeDark {
  background-color: var(--pinkOrangeDark) !important;
}
.bg-pinkOrange {
  background-color: var(--pinkOrange) !important;
}
.bg-lightPink {
  background-color: var(--lightPink);
}
.bg-pinkDark {
  background-color: var(--pinkDark) !important;
}
.bg-redDark {
  background-color: #c2503a !important;
}
.bg-redDarker {
  background-color: #a6402a !important;
}
.bg-greenGray {
  background-color: var(--greenGray) !important;
}
.bg-grayGreen {
  background-color: var(--grayGreen) !important;
}
.bg-beigeYellow{
background-color: var(--beigeYellow) !important;
}
.bg-beigeGray{
  background-color: var(--beigeGray) !important;
}
.bg-beigeDark{
  background-color: var(--beigeDark) !important;
}
.img-banner-text-13 {
  background-color: #93a4d8 !important;
}
.img-banner-text-14 {
  background-color: #cc86c2 !important;
}
.img-banner-text-15 {
  background-color: #fe959a !important;
}

.img-banner-text-21 {
  background-color: #fff6e5 !important;
}
.img-banner-text-21-5 {
  background-color: #fcf4e8 !important;
}

.img-banner-text-23 {
  background-color: #8f9e9d !important;
}
.img-banner-text-24 {
  background-color: #c2503a !important;
}
.bg-pinkOrange {
  background-color: #f8ab94 !important;
}
.text-pink {
  color: #ffddda !important;
}
.text-beigeYellow {
  color: #fff6e5 !important;
}  
.text-beigeGray {
  color: var(--beigeGray)!important;
}
.text-pink-dark {
  color: #fe959a !important;
}
.text-gray-green-light
{
  color: #67767680;
}
.text-pink-orange {
  color: #f8ab94 !important;
}
.text-pink-orange-dark {
  color: var(--pinkOrangeDark) !important;
}

.text-green-dark {
  color:#4d4f40 !important ;
}
.text-gray-green {
  color: var(--grayGreen) !important;
}
.text-red-dark {
  color: var(--redDark) !important;
}
.tutorCard .overal-stars,
.tutorPage .overal-stars {
  color: #f8ab94 !important;
}

.demy-fp {
  background-color: #fff6e5;
  /* background-color: #FFDDDA; */
  /* height: 70vh; */
}
.demy-cat {
  max-width: 200px;
  cursor: pointer;
}
.comment-cell {
  white-space: nowrap; /* Prevents line breaks */
  overflow: hidden; /* Hides overflowing content */
  text-overflow: ellipsis; /* Displays an ellipsis (...) when content overflows */
  width: 300px; /* Specify your desired width */
  max-width: 100%; /* Allow the cell to expand up to 100% of its container width */
}
.weekdays {
  /* border-top: 3px #e7ab3c   solid; */
  border-top: 3px var(--redDark) solid;
  color: rgb(90, 72, 29);
  font-size: 9px;
}
.col-1-7 {
  flex: 0 0 auto;
  width: 14.2857143%;
}
.video-frame {
  border-radius: 30px !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,
.css-9e5uuu-MuiPaper-root-MuiAutocomplete-paper,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-38raov-MuiButtonBase-root-MuiChip-root,
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-family: vazir !important;
  color: var(--brownGray) !important;
  border: none !important;
}
.css-gdh49b-MuiAutocomplete-listbox
  .MuiAutocomplete-option[aria-selected="true"].Mui-focused,
.css-gdh49b-MuiAutocomplete-listbox
  .MuiAutocomplete-option[aria-selected="true"] {
  background-color: rgba(195, 165, 135, 0.245) !important;
}
.day-time {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 6px;
}
/* .metademy-page{
  font-family: 'Nunito' !important;
} */
/* .metademy-page .titr7, .titr5,  .titr1{
  font-family: 'Nunito' !important; 
} */
.metademy-page .card {
  background-color: #f7f5f3 !important;
}
.tutor-btn,
.tutor-btn a {
  font-size: 16px !important;
  letter-spacing: 2px;
  text-align: center;
  color: #ffffff !important;
  padding: 12px 30px 12px;
  /* background: #2E2562 !important; */
  background-color: #4d4f40 !important;
  transition: all 0.5s ease-in-out;
}
.tutor-btn:hover {
  background: transparent !important;
  color: #4d4f40 !important ;
  border: 1px solid #4d4f40 !important;
}
.tutor-btn2,
.tutor-btn2 a {
  font-size: 16px !important;
  letter-spacing: 2px;
  text-align: center;
  color: #ffffff !important;
  padding: 12px 30px 12px;
  /* background: #FE959A !important; */
  background-color: #f8ab94 !important;
}
.tutor-btn2:hover {
  background: transparent !important;
  color: #f8ab94 !important ;
  border: 1px solid #f8ab94 !important;
  transition: all 0.5s ease-in-out;
}
.metademy-page .titr5,
.metademy-page .titr6,
.metademy-page .titr7 {
  color: #4d4f40;
}

.metademy-page .titr1 {
  font-size: 3.5em !important;
  font-stretch: expanded !important;
}
@media (max-width: 768px) {
  .metademy-page .titr1 {
    font-size: 2.25em !important;
    font-stretch: normal !important;
  }
}
.metademy-page-en .titr1 {
  font-size: 4.5em !important;
  font-stretch: expanded !important;
}
@media (max-width: 768px) {
  .metademy-page-en .titr1 {
    font-size: 3.5em !important;
    font-stretch: normal !important;
  }
}

.tutor-frame {
  border-radius: 10px;

  overflow: hidden;
}
.nav-bg {
  background-color: #4d4f40 !important;
}
.mw-75 {
  max-width: 75% !important;
}

.half-red-creme {
  background: linear-gradient(to bottom, #f8ab94 50%, #fff6e5 50%);
}
.tutorCard .icon-stack {
  color: var(--deepGreen) ;
  border-color: var(--deepGreen) !important;
}
.tutorCard .icon-stack:hover,
.tutorCard .icon-stack-1x:hover {
  background-color: var(--deepGreen) !important;
  color: var(--beigeYellow) !important;
  border-color: var(--beigeYellow) !important;
}
.tutorPage .checkout-btn a {
  background: transparent !important;
}
.tutorPage .checkout-btn a:hover {
  background: transparent !important;
  color: var(--deepGreen) !important;
}
.tutorCard .checkout-btn:hover,
.tutorCard .checkout-btn:active,
.tutorCard .checkout-btn:focus,
.tutorPage .checkout-btn:hover,
.tutorPage .checkout-btn:active,
.tutorPage .checkout-btn:focus,
.tutorPage .button-filter:hover {
  background-color: var(--beigeDark) !important;
  color: var(--deepGreen) !important;
}
.metademy-en{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
 
}

.times-tutor{
  height: 42px;
}
.times-tutor:hover {
  background-color: #87a29431;
  border-radius: 10px;
}
.times-tutor-selected {
  background-color: var(--pinkOrange);
  border-radius: 5px;
}

.tutorPage .claim-box {
  border: none;
}
.tutorPage .activeBtn, .tutorPage .activeBtn:hover{
  background-color: var(--pinkOrangeDark) !important;
  border: 3px solid var(--deepGreen) !important;
}
.tutorPage .false {
  background-color: #f8ab948c !important;
  border: 3px solid #4d4f400d !important;
}
.tutorPage .false:hover
 {
  background-color: #db7e5cbb !important;
  color: var(--beigeYellow) !important;
  border: 3px solid #4d4f40c4 !important;
}
.selected-session,
.selected-session:hover {
  background-color: var(--beigeDark);
  border-radius: 10px;
}
.test-session,
.test-session:hover {
  background-color: var(--lightPink);
  border-radius: 3px;
}
.booked-session,
.booked-session:hover {
  background-color: rgb(182, 172, 172);
  border-radius: 3px;
  color: var(--beigeGray);
}
.canceled-session,
.canceled-session:hover {
  background-color: var(--pinkOrangeDark);
  border-radius: 3px;
  color: var(--beigeGray);
}
.confirmed-session,
.confirmed-session:hover {
  background-color: var(--pinkOrange);
  border-radius: 3px;
  color: var(--beigeGray);
}
.completed-session,
.completed-session:hover {
  background-color: var(--greenGray);
  border-radius: 3px;
  color: var(--beigeGray);
}
.noShowedS-session,
.noShowedS-session:hover {
  background-color: var(--beigeDark);
  border-radius: 3px;
  /* color: var(--beigeGray); */
}
.refunded-session,
.refunded-session:hover {
  background-color: var(--beigeYellow);
  border-radius: 3px;
  /* color: var(--beigeGray); */
}
.notRefunded-session,
.notRefunded-session:hover {

  background-color: var(--beigeYellow);
  
  background-color: var(--redDark);
  border-radius: 3px;
  color: var(--beigeGray);
 
}
.tutorsStudentsGuide{
  
  line-height: 30px;
}
.tutorsStudentsGuide .nav-pills .nav-link,
.tutorsStudentsGuide .nav-pills .show > .tutorsStudentsGuide .nav-link {
  color:  var(--grayGreen) ;
  background: none;
  border: 2px solid var(--grayGreen) ;
  border-radius: 20px;
  font-weight: bold;
}

.tutorsStudentsGuide .nav-pills .nav-link.active {
  color: #fff;
  background-color:  var(--grayGreen) !important;
}

.tutorsStudentsGuide .nav-tabs .nav-link{
  color:var(--pinkOrangeDark);
 
}
.tutorsStudentsGuide .nav-tabs .nav-item.show .nav-link,.tutorsStudentsGuide .nav-tabs .nav-link.active{
  color:var(--beigeYellow);
  background-color: var(--pinkOrangeDark) !important;
}
.tutorsStudentsGuide th, .tutorsStudentsGuide td{
  border: 1px solid var(--grayGreen);
  padding: 8px 12px ;
  text-align: center;
  font-size: 12px;
}
.tutorsStudentsGuide th{
  background-color: var(--beigeDark);
}
.prof-tag {
  font-size: 12px;
  color: var(--beigeYellow);
  background: var(--pinkOrangeDark);
  font-weight: 600;
  display: inline-block;
  padding:  1px 5px 1px 15px;
  border-radius: 5px;
  /* position: absolute; */
  /* right: 5px; */
  /* bottom: 5px; */
  
}
.prof-tag:hover {
 color: var(--pinkOrangeDark);
 background: var(--beigeYellow);
  transition:all 0.3s ease-in-out  ;
  cursor: pointer;
}
.prof-tag2{
  
  background: var(--coldBrown) ;

}
.accordion-button3 {
  background-color: var(--grayGreen);
  color: var(--beigeYellow);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  max-width: 700px;
 
}

.accordion-button3:not(.collapsed) {
  background-color: var(--beigeYellow);
  color: var(--grayGreen);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  transition: all 0.5s ease-in-out;
}
.tutorPage .picture{

  background-color: transparent !important;
  border: 1px solid var(--grayGreen);
  object-fit: cover;
}
.my-6{
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.my-7{
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;

}
.m-t-7{
  margin-top: 5rem !important;

}
.my-10{
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;

}