

@media all and (min-width: 768px) {
    .dashboardContainer{
        /* font-family: 'Source Sans Pro', sans-serif; */
        margin: 0;
        /* direction: ltr; */
        display: flex;   
        /* padding-top: 10px; */
        color: rgb(61, 47, 10);
    }
}
@media all and (max-width: 767px) {
    .dashboardContainer{
      
        margin: 0;
     
        display: block;   
    
        color: rgb(61, 47, 10);
    }
    .sidebar-dash .sidebar{
        /* display: contents; */
        width: 100%;
    }
}








