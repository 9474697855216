.leftSize_main{
    height: 100vh;
    width: 200px;
    border-right: 2px solid #dddddd;
    padding: 40px;
}
.leftSide_header{
font-weight:bold ;
margin: 10px;
}

.brandname{
display: block;
}